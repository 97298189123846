/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  JSON: { input: any; output: any };
  Long: { input: number; output: number };
};

export type AddParametersInput = {
  chooseBilling: Scalars['Boolean']['input'];
  doctorId: Scalars['ID']['input'];
  parameterInputs: Array<ParameterToAddInput>;
  patientData: PatientDataInput;
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
  selectedParameters: Array<SelectedParameterInput>;
};

export type AddParametersResponse = {
  __typename?: 'AddParametersResponse';
  incompatibleParams: Array<Scalars['String']['output']>;
  paramsToAdd: Array<ParameterToAdd>;
};

export enum AisExportType {
  CSV = 'CSV',
  PDF = 'PDF',
}

export enum AisImportType {
  BHZ_KRAUS_LABENE = 'BHZ_KRAUS_LABENE',
  GENERIC_ANSI = 'GENERIC_ANSI',
  GENERIC_UTF8 = 'GENERIC_UTF8',
}

export type AssignedLab = {
  __typename?: 'AssignedLab';
  customerId: Scalars['String']['output'];
  lab: Lab;
  runningNumber: Scalars['Long']['output'];
  supplier: TestTubeSupplier;
};

export type AssignedLabInput = {
  customerId: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
  overrideRunningNumber: Scalars['Boolean']['input'];
  runningNumber: Scalars['Long']['input'];
  supplier: TestTubeSupplier;
};

export type AvailableBillingInfos = {
  __typename?: 'AvailableBillingInfos';
  billingInfos: Array<BillingInfo>;
  exceededPeriodicGroups: Array<ExceededPeriodicGroup>;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  additionalText: Scalars['String']['output'];
  billingType: BillingType;
  diagnoseId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['BigDecimal']['output']>;
  specialRateId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export enum BillingPreference {
  ASK = 'ASK',
  DOCTOR = 'DOCTOR',
  PATIENT = 'PATIENT',
}

export enum BillingType {
  DIAGNOSE = 'DIAGNOSE',
  DOCTOR = 'DOCTOR',
  INSURANCE = 'INSURANCE',
  LG_DIAGNOSE = 'LG_DIAGNOSE',
  LG_DOCTOR = 'LG_DOCTOR',
  LG_INSURANCE = 'LG_INSURANCE',
  PATIENT = 'PATIENT',
  SPECIAL_RATE = 'SPECIAL_RATE',
}

export type Catalog = {
  __typename?: 'Catalog';
  catalogParameters: Array<CatalogParameter>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lab: Lab;
  name: Scalars['String']['output'];
};

export type CatalogCreateInput = {
  description: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parameterIds: Array<Scalars['String']['input']>;
};

export type CatalogParameter = {
  __typename?: 'CatalogParameter';
  catalog: Catalog;
  doctorBilling: Scalars['Boolean']['output'];
  forms: Array<Form>;
  id: Scalars['ID']['output'];
  insuranceDefinitions: Array<InsuranceDefinition>;
  lgDoctorBilling: Scalars['Boolean']['output'];
  parameter: Parameter;
  patientBilling: Scalars['Boolean']['output'];
  priceDoctor?: Maybe<Scalars['BigDecimal']['output']>;
  priceLgDoctor?: Maybe<Scalars['BigDecimal']['output']>;
  pricePatient?: Maybe<Scalars['BigDecimal']['output']>;
  specialRates: Array<SpecialRate>;
  specimens: Array<Specimen>;
  useDoctorBilling: Scalars['Boolean']['output'];
  useForms: Scalars['Boolean']['output'];
  useInsuranceDefinitions: Scalars['Boolean']['output'];
  useLgDoctorBilling: Scalars['Boolean']['output'];
  useLgInsuranceDefinitions: Scalars['Boolean']['output'];
  usePatientBilling: Scalars['Boolean']['output'];
  usePriceDoctor: Scalars['Boolean']['output'];
  usePriceLgDoctor: Scalars['Boolean']['output'];
  usePricePatient: Scalars['Boolean']['output'];
  useSpecialRates: Scalars['Boolean']['output'];
  useSpecimens: Scalars['Boolean']['output'];
};

export type CatalogParameterUpdateInput = {
  doctorBilling: Scalars['Boolean']['input'];
  formIds: Array<Scalars['String']['input']>;
  insuranceDefinitions: Array<InsuranceDefinitionInput>;
  lgDoctorBilling: Scalars['Boolean']['input'];
  patientBilling: Scalars['Boolean']['input'];
  priceDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceLgDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  pricePatient?: InputMaybe<Scalars['BigDecimal']['input']>;
  specialRateIds: Array<Scalars['String']['input']>;
  specimenIds: Array<Scalars['String']['input']>;
  useDoctorBilling: Scalars['Boolean']['input'];
  useForms: Scalars['Boolean']['input'];
  useInsuranceDefinitions: Scalars['Boolean']['input'];
  useLgDoctorBilling: Scalars['Boolean']['input'];
  useLgInsuranceDefinitions: Scalars['Boolean']['input'];
  usePatientBilling: Scalars['Boolean']['input'];
  usePriceDoctor: Scalars['Boolean']['input'];
  usePriceLgDoctor: Scalars['Boolean']['input'];
  usePricePatient: Scalars['Boolean']['input'];
  useSpecialRates: Scalars['Boolean']['input'];
  useSpecimens: Scalars['Boolean']['input'];
};

export type CatalogUpdateInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parameterIds: Array<Scalars['String']['input']>;
};

export type CheckParametersResult = {
  __typename?: 'CheckParametersResult';
  selectedParameters: Array<SelectedParameter>;
  unavailableParameters: Array<Scalars['String']['output']>;
  unknownBillingParameters: Array<UnknownBillingParameter>;
};

export type CopyCatalogParametersInput = {
  catalogParameterIds: Array<Scalars['String']['input']>;
  overrideExisting: Scalars['Boolean']['input'];
  sourceCatalogId: Scalars['ID']['input'];
  targetCatalogIds: Array<Scalars['String']['input']>;
};

export type CreateDoctorFrontDeskInput = {
  city: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hvNumber: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  meAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postTitle: Scalars['String']['input'];
  preTitle: Scalars['String']['input'];
  salutation: DoctorSalutation;
  specialist: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CreateRequestInput = {
  acute: Scalars['Boolean']['input'];
  additionalData: Scalars['JSON']['input'];
  diagnose: Scalars['String']['input'];
  doctorId: Scalars['ID']['input'];
  formFieldValues: Array<FormFieldValuesInput>;
  labInfo: Scalars['String']['input'];
  localizations: Array<LocalizationInput>;
  patientData: PatientDataInput;
  sampledAt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedParameters: Array<SelectedParameterInput>;
};

export type DashboardData = {
  __typename?: 'DashboardData';
  openRequests: Scalars['Long']['output'];
  sentRequestsMonth: Scalars['Long']['output'];
  sentRequestsToday: Scalars['Long']['output'];
  sentRequestsWeek: Scalars['Long']['output'];
  sentRequestsYear: Scalars['Long']['output'];
};

export type Diagnose = {
  __typename?: 'Diagnose';
  id: Scalars['ID']['output'];
  lab: Lab;
  name: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type DiagnoseCreateInput = {
  labId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type DiagnoseUpdateInput = {
  name: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type Doctor = {
  __typename?: 'Doctor';
  addEmptyLabels: Scalars['Int']['output'];
  aisExportTypes: Array<AisExportType>;
  aisImportDoctorId: Scalars['String']['output'];
  aisImportType: AisImportType;
  assignedLabs: Array<AssignedLab>;
  autoDiagnose: Scalars['Boolean']['output'];
  autoSpecialRate: Scalars['Boolean']['output'];
  billingPreference: BillingPreference;
  catalogs: Array<Catalog>;
  city: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  csvImportEnabled: Scalars['Boolean']['output'];
  disabled: Scalars['Boolean']['output'];
  doctorBilling: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  flipParamNames: Scalars['Boolean']['output'];
  hasAis: Scalars['Boolean']['output'];
  hvNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insurances: Array<Insurance>;
  lastName: Scalars['String']['output'];
  lgDoctorBilling: Scalars['Boolean']['output'];
  lgInsurances: Array<Insurance>;
  meAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  overrideRequestEnabled: Scalars['Boolean']['output'];
  patientBilling: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  postTitle: Scalars['String']['output'];
  preTitle: Scalars['String']['output'];
  preferSpecialRate: Scalars['Boolean']['output'];
  printTransferDocument: Scalars['Boolean']['output'];
  salutation: DoctorSalutation;
  showPriceSuggestion: Scalars['Boolean']['output'];
  showPrices: Scalars['Boolean']['output'];
  specialRates: Array<SpecialRate>;
  specialist: Scalars['String']['output'];
  street: Scalars['String']['output'];
  theme?: Maybe<Theme>;
  zip: Scalars['String']['output'];
};

export type DoctorInput = {
  addEmptyLabels: Scalars['Int']['input'];
  aisExportTypes: Array<AisExportType>;
  aisImportDoctorId: Scalars['String']['input'];
  aisImportType: AisImportType;
  assignedLabs: Array<AssignedLabInput>;
  autoDiagnose: Scalars['Boolean']['input'];
  autoSpecialRate: Scalars['Boolean']['input'];
  billingPreference: BillingPreference;
  catalogIds: Array<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  csvImportEnabled: Scalars['Boolean']['input'];
  disabled: Scalars['Boolean']['input'];
  doctorBilling: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  flipParamNames: Scalars['Boolean']['input'];
  hasAis: Scalars['Boolean']['input'];
  hvNumber: Scalars['String']['input'];
  insuranceIds: Array<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  lgDoctorBilling: Scalars['Boolean']['input'];
  lgInsuranceIds: Array<Scalars['String']['input']>;
  meAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  overrideRequestEnabled: Scalars['Boolean']['input'];
  patientBilling: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
  postTitle: Scalars['String']['input'];
  preTitle: Scalars['String']['input'];
  preferSpecialRate: Scalars['Boolean']['input'];
  printTransferDocument: Scalars['Boolean']['input'];
  salutation: DoctorSalutation;
  showPriceSuggestion: Scalars['Boolean']['input'];
  showPrices: Scalars['Boolean']['input'];
  specialRateIds: Array<Scalars['String']['input']>;
  specialist: Scalars['String']['input'];
  street: Scalars['String']['input'];
  themeId?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export enum DoctorSalutation {
  FIRMA = 'FIRMA',
  FRAU = 'FRAU',
  GRUPPENPRAXIS = 'GRUPPENPRAXIS',
  HERR = 'HERR',
}

export type DoctorSettingsInput = {
  addEmptyLabels: Scalars['Int']['input'];
  autoDiagnose: Scalars['Boolean']['input'];
  billingPreference: BillingPreference;
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postTitle: Scalars['String']['input'];
  preTitle: Scalars['String']['input'];
  printTransferDocument: Scalars['Boolean']['input'];
  salutation: DoctorSalutation;
  specialist: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type ExceededPeriodicGroup = {
  __typename?: 'ExceededPeriodicGroup';
  lg: Scalars['Boolean']['output'];
  paramsInGroup: Array<Scalars['String']['output']>;
  periodicGroup: PeriodicGroup;
};

export type Form = {
  __typename?: 'Form';
  customizer: FormCustomizer;
  description: Scalars['String']['output'];
  direct: Scalars['Boolean']['output'];
  fields: Array<FormField>;
  hasDocument: Scalars['Boolean']['output'];
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lab: Lab;
  name: Scalars['String']['output'];
  pickup: Scalars['Boolean']['output'];
  printBeforeSend: Scalars['Boolean']['output'];
  printCopies: Scalars['Int']['output'];
  printRequired: Scalars['Boolean']['output'];
  restore: Scalars['Boolean']['output'];
};

export enum FormCustomizer {
  ENML_RENNER_SCHEIN = 'ENML_RENNER_SCHEIN',
  NONE = 'NONE',
}

export type FormField = {
  __typename?: 'FormField';
  defaultValue: Scalars['String']['output'];
  form: Form;
  help: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<FormFieldOption>;
  placeholder: Scalars['String']['output'];
  regex: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: FormFieldType;
};

export type FormFieldInput = {
  defaultValue: Scalars['String']['input'];
  help: Scalars['String']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options: Array<FormFieldOptionInput>;
  placeholder: Scalars['String']['input'];
  regex: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  type: FormFieldType;
};

export type FormFieldOption = {
  __typename?: 'FormFieldOption';
  label: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type FormFieldOptionInput = {
  label: Scalars['String']['input'];
  selected: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export enum FormFieldType {
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  DATE = 'DATE',
  INPUT = 'INPUT',
  OPTION_GROUP = 'OPTION_GROUP',
  SELECT = 'SELECT',
}

export type FormFieldValuesInput = {
  formFieldId: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type FormInput = {
  customizer: FormCustomizer;
  description: Scalars['String']['input'];
  direct: Scalars['Boolean']['input'];
  fields: Array<FormFieldInput>;
  header: Scalars['String']['input'];
  labId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pickup: Scalars['Boolean']['input'];
  printBeforeSend: Scalars['Boolean']['input'];
  printCopies: Scalars['Int']['input'];
  printRequired: Scalars['Boolean']['input'];
  restore: Scalars['Boolean']['input'];
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum HotKey {
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
}

export enum IdCardType {
  BEHINDERTENAUSWEIS = 'BEHINDERTENAUSWEIS',
  FUEHERSCHEIN = 'FUEHERSCHEIN',
  NICHT_VORHANDEN = 'NICHT_VORHANDEN',
  PERSONALAUSWEIS = 'PERSONALAUSWEIS',
  REISEPASS = 'REISEPASS',
  SCHUELERAUSWEIS = 'SCHUELERAUSWEIS',
  SONSTIGER_AUSWEIS = 'SONSTIGER_AUSWEIS',
  STUDENTENAUSWEIS = 'STUDENTENAUSWEIS',
  WEHRDIENSTAUSWEIS = 'WEHRDIENSTAUSWEIS',
}

export enum ImportType {
  DB_TOOL = 'DB_TOOL',
}

export type ImportedDoctor = {
  __typename?: 'ImportedDoctor';
  city: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hvNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  labId: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  meAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  postTitle: Scalars['String']['output'];
  preTitle: Scalars['String']['output'];
  salutation: DoctorSalutation;
  specialist: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type Insurance = {
  __typename?: 'Insurance';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mappings: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export enum InsuranceCategory {
  ERWERBSTAETIGE = 'ERWERBSTAETIGE',
  FREMDSTAATEN = 'FREMDSTAATEN',
  KOVG = 'KOVG',
  KOVGD = 'KOVGD',
  KRIEGSHINTERBLIEBENE = 'KRIEGSHINTERBLIEBENE',
  MKP = 'MKP',
  OFG = 'OFG',
  PENSIONISTEN = 'PENSIONISTEN',
  STVG = 'STVG',
  VOG = 'VOG',
  VU = 'VU',
}

export type InsuranceDefinition = {
  __typename?: 'InsuranceDefinition';
  catalogParameter?: Maybe<CatalogParameter>;
  diagnoses: Array<Diagnose>;
  id: Scalars['ID']['output'];
  insurance: Insurance;
  lg: Scalars['Boolean']['output'];
  parameter?: Maybe<Parameter>;
  periodicGroup?: Maybe<PeriodicGroup>;
};

export type InsuranceDefinitionInput = {
  diagnoseIds: Array<Scalars['String']['input']>;
  insuranceId: Scalars['ID']['input'];
  lg: Scalars['Boolean']['input'];
  periodicGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsuranceInput = {
  code: Scalars['String']['input'];
  mappings: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  shortName: Scalars['String']['input'];
};

export type InsuredPerson = {
  __typename?: 'InsuredPerson';
  birthday: Scalars['Date']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  street: Scalars['String']['output'];
  svnr: Scalars['String']['output'];
  title: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type InsuredPersonInput = {
  birthday: Scalars['Date']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  svnr: Scalars['String']['input'];
  title: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type Lab = {
  __typename?: 'Lab';
  city: Scalars['String']['output'];
  diagnoseRequired: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  hasAisExportDocument: Scalars['Boolean']['output'];
  hasTransferDocument: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  invoiceCounter: Scalars['Long']['output'];
  longName: Scalars['String']['output'];
  meAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  newReorderRunningNumber: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  pickupTransferDocument: Scalars['Boolean']['output'];
  printPartnerLab1Params: Scalars['Boolean']['output'];
  printPartnerLab2Params: Scalars['Boolean']['output'];
  printPartnerLab3Params: Scalars['Boolean']['output'];
  runningNumber: Scalars['Long']['output'];
  shortName: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
  zplLabelHeight: Scalars['Int']['output'];
  zplLabelTemplate: Scalars['String']['output'];
  zplLabelWidth: Scalars['Int']['output'];
};

export type LabInput = {
  city: Scalars['String']['input'];
  diagnoseRequired: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  fax: Scalars['String']['input'];
  invoiceCounter: Scalars['Long']['input'];
  longName: Scalars['String']['input'];
  meAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  newReorderRunningNumber: Scalars['Boolean']['input'];
  overrideInvoiceCounter: Scalars['Boolean']['input'];
  overrideRunningNumber: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
  pickupTransferDocument: Scalars['Boolean']['input'];
  printPartnerLab1Params: Scalars['Boolean']['input'];
  printPartnerLab2Params: Scalars['Boolean']['input'];
  printPartnerLab3Params: Scalars['Boolean']['input'];
  runningNumber: Scalars['Long']['input'];
  shortName: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
  zplLabelHeight: Scalars['Int']['input'];
  zplLabelTemplate: Scalars['String']['input'];
  zplLabelWidth: Scalars['Int']['input'];
};

export type Localization = {
  __typename?: 'Localization';
  location: Scalars['String']['output'];
  paramId: Scalars['String']['output'];
  specimenId: Scalars['String']['output'];
};

export type LocalizationInput = {
  location: Scalars['String']['input'];
  paramId: Scalars['String']['input'];
  specimenId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addParameters?: Maybe<AddParametersResponse>;
  cancelOrderInvoice?: Maybe<Order>;
  cloneCatalog?: Maybe<Catalog>;
  compileParams?: Maybe<Scalars['Long']['output']>;
  copyCatalogParameters?: Maybe<Scalars['String']['output']>;
  createCatalog?: Maybe<Catalog>;
  createDiagnose?: Maybe<Diagnose>;
  createDoctor?: Maybe<Doctor>;
  createDoctorFrontDesk?: Maybe<Doctor>;
  createForm?: Maybe<Form>;
  createInsurance?: Maybe<Insurance>;
  createParameter?: Maybe<Parameter>;
  createPeriodicGroup?: Maybe<PeriodicGroup>;
  createProfile?: Maybe<Profile>;
  createRequest?: Maybe<Request>;
  createSpecialRate?: Maybe<SpecialRate>;
  createSpecimen?: Maybe<Specimen>;
  createTestTube?: Maybe<TestTube>;
  createTheme?: Maybe<Theme>;
  deleteAllOpenRequests?: Maybe<Scalars['Boolean']['output']>;
  deleteCatalog?: Maybe<Scalars['Boolean']['output']>;
  deleteFormDocument?: Maybe<Form>;
  deleteLabAisExportDocument?: Maybe<Lab>;
  deleteLabTransferDocument?: Maybe<Lab>;
  deleteOpenRequest?: Maybe<Scalars['Boolean']['output']>;
  deleteProfile?: Maybe<Scalars['Boolean']['output']>;
  deleteTestTubeImage?: Maybe<TestTube>;
  deleteUserAssignment?: Maybe<Scalars['Boolean']['output']>;
  markAisExported?: Maybe<Request>;
  markOrderFilePrinted?: Maybe<OrderFile>;
  markOrderLabelPrinted?: Maybe<OrderLabel>;
  markPrinted?: Maybe<Request>;
  reorder?: Maybe<Request>;
  saveDoctorSheet?: Maybe<Sheet>;
  saveLabSheet?: Maybe<Sheet>;
  saveUserAssignment?: Maybe<UserAssignment>;
  sendRequests?: Maybe<Array<Request>>;
  updateCatalog?: Maybe<Catalog>;
  updateCatalogParameters?: Maybe<Array<CatalogParameter>>;
  updateDiagnose?: Maybe<Diagnose>;
  updateDoctor?: Maybe<Doctor>;
  updateDoctorSettings?: Maybe<Doctor>;
  updateForm?: Maybe<Form>;
  updateInsurance?: Maybe<Insurance>;
  updateLab?: Maybe<Lab>;
  updateParameter?: Maybe<Parameter>;
  updatePeriodicGroup?: Maybe<PeriodicGroup>;
  updateProfile?: Maybe<Profile>;
  updateRequest?: Maybe<Request>;
  updateSpecialRate?: Maybe<SpecialRate>;
  updateSpecimen?: Maybe<Specimen>;
  updateSystemStatus?: Maybe<SystemStatus>;
  updateTestTube?: Maybe<TestTube>;
  updateTheme?: Maybe<Theme>;
};

export type MutationAddParametersArgs = {
  input: AddParametersInput;
};

export type MutationCancelOrderInvoiceArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationCloneCatalogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCopyCatalogParametersArgs = {
  input: CopyCatalogParametersInput;
};

export type MutationCreateCatalogArgs = {
  input: CatalogCreateInput;
};

export type MutationCreateDiagnoseArgs = {
  input: DiagnoseCreateInput;
};

export type MutationCreateDoctorArgs = {
  input: DoctorInput;
};

export type MutationCreateDoctorFrontDeskArgs = {
  input: CreateDoctorFrontDeskInput;
};

export type MutationCreateFormArgs = {
  input: FormInput;
};

export type MutationCreateInsuranceArgs = {
  input: InsuranceInput;
};

export type MutationCreateParameterArgs = {
  input: ParameterCreateInput;
};

export type MutationCreatePeriodicGroupArgs = {
  input: PeriodicGroupCreateInput;
};

export type MutationCreateProfileArgs = {
  input: ProfileInput;
};

export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};

export type MutationCreateSpecialRateArgs = {
  input: SpecialRateCreateInput;
};

export type MutationCreateSpecimenArgs = {
  input: SpecimenCreateInput;
};

export type MutationCreateTestTubeArgs = {
  input: TestTubeCreateInput;
};

export type MutationCreateThemeArgs = {
  input: ThemeInput;
};

export type MutationDeleteAllOpenRequestsArgs = {
  doctorId: Scalars['ID']['input'];
};

export type MutationDeleteCatalogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFormDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLabAisExportDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLabTransferDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOpenRequestArgs = {
  requestId: Scalars['ID']['input'];
};

export type MutationDeleteProfileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTestTubeImageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkAisExportedArgs = {
  requestId: Scalars['ID']['input'];
};

export type MutationMarkOrderFilePrintedArgs = {
  orderFileId: Scalars['ID']['input'];
};

export type MutationMarkOrderLabelPrintedArgs = {
  orderLabelId: Scalars['ID']['input'];
};

export type MutationMarkPrintedArgs = {
  beforeSend: Scalars['Boolean']['input'];
  requestId: Scalars['ID']['input'];
};

export type MutationReorderArgs = {
  input: ReorderInput;
};

export type MutationSaveDoctorSheetArgs = {
  doctorId: Scalars['ID']['input'];
  input: SheetInput;
};

export type MutationSaveLabSheetArgs = {
  input: SheetInput;
  labId: Scalars['ID']['input'];
};

export type MutationSaveUserAssignmentArgs = {
  input: UserAssignmentInput;
};

export type MutationSendRequestsArgs = {
  requestIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateCatalogArgs = {
  id: Scalars['ID']['input'];
  input: CatalogUpdateInput;
};

export type MutationUpdateCatalogParametersArgs = {
  ids: Array<Scalars['ID']['input']>;
  input: CatalogParameterUpdateInput;
};

export type MutationUpdateDiagnoseArgs = {
  id: Scalars['ID']['input'];
  input: DiagnoseUpdateInput;
};

export type MutationUpdateDoctorArgs = {
  id: Scalars['ID']['input'];
  input: DoctorInput;
};

export type MutationUpdateDoctorSettingsArgs = {
  id: Scalars['ID']['input'];
  input: DoctorSettingsInput;
};

export type MutationUpdateFormArgs = {
  id: Scalars['ID']['input'];
  input: FormInput;
};

export type MutationUpdateInsuranceArgs = {
  id: Scalars['ID']['input'];
  input: InsuranceInput;
};

export type MutationUpdateLabArgs = {
  id: Scalars['ID']['input'];
  input: LabInput;
};

export type MutationUpdateParameterArgs = {
  id: Scalars['ID']['input'];
  input: ParameterUpdateInput;
};

export type MutationUpdatePeriodicGroupArgs = {
  id: Scalars['ID']['input'];
  input: PeriodicGroupUpdateInput;
};

export type MutationUpdateProfileArgs = {
  id: Scalars['ID']['input'];
  input: ProfileInput;
};

export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};

export type MutationUpdateSpecialRateArgs = {
  id: Scalars['ID']['input'];
  input: SpecialRateUpdateInput;
};

export type MutationUpdateSpecimenArgs = {
  id: Scalars['ID']['input'];
  input: SpecimenUpdateInput;
};

export type MutationUpdateSystemStatusArgs = {
  input: SystemStatusInput;
};

export type MutationUpdateTestTubeArgs = {
  id: Scalars['ID']['input'];
  input: TestTubeUpdateInput;
};

export type MutationUpdateThemeArgs = {
  id: Scalars['ID']['input'];
  input: ThemeInput;
};

export type Order = {
  __typename?: 'Order';
  acute: Scalars['Boolean']['output'];
  aisExportedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByFirstName: Scalars['String']['output'];
  createdByLastName: Scalars['String']['output'];
  diagnose: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceCancellationDate?: Maybe<Scalars['Date']['output']>;
  invoiceCancellationNumber?: Maybe<Scalars['String']['output']>;
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceSum?: Maybe<Scalars['BigDecimal']['output']>;
  lab: Lab;
  labInfo: Scalars['String']['output'];
  number: Scalars['String']['output'];
  orderFiles: Array<OrderFile>;
  orderForms: Array<OrderForm>;
  orderLabels: Array<OrderLabel>;
  orderParameters: Array<OrderParameter>;
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  request: Request;
  resultIds: Array<Scalars['String']['output']>;
  type: OrderType;
};

export type OrderFile = {
  __typename?: 'OrderFile';
  checksum: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pickup: Scalars['Boolean']['output'];
  printBeforeSend: Scalars['Boolean']['output'];
  printCopies: Scalars['Int']['output'];
  printRequired: Scalars['Boolean']['output'];
  printable: Scalars['Boolean']['output'];
  printedAt?: Maybe<Scalars['DateTime']['output']>;
  size: Scalars['Long']['output'];
  type: OrderFileType;
};

export enum OrderFileType {
  ETRON = 'ETRON',
  FORM_DOCUMENT = 'FORM_DOCUMENT',
  HL7 = 'HL7',
  INVOICE = 'INVOICE',
  TRANSFER_DOCUMENT = 'TRANSFER_DOCUMENT',
}

export type OrderForm = {
  __typename?: 'OrderForm';
  customizer: FormCustomizer;
  fields: Array<OrderFormField>;
  formId: Scalars['String']['output'];
  hasDocument: Scalars['Boolean']['output'];
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  pickup: Scalars['Boolean']['output'];
  printBeforeSend: Scalars['Boolean']['output'];
  printCopies: Scalars['Int']['output'];
  printRequired: Scalars['Boolean']['output'];
  restore: Scalars['Boolean']['output'];
};

export type OrderFormField = {
  __typename?: 'OrderFormField';
  formFieldId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  label: Scalars['String']['output'];
  orderForm: OrderForm;
  type: FormFieldType;
  values: Array<Scalars['String']['output']>;
};

export type OrderLabel = {
  __typename?: 'OrderLabel';
  classification: Scalars['String']['output'];
  color: TestTubeColor;
  features: Array<SpecimenFeature>;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  order: Order;
  paramShortNames: Array<Scalars['String']['output']>;
  printedAt?: Maybe<Scalars['DateTime']['output']>;
  repetition: Scalars['Int']['output'];
  specimenId: Scalars['String']['output'];
  testTubeId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  text2: Scalars['String']['output'];
};

export type OrderParameter = {
  __typename?: 'OrderParameter';
  acute: Scalars['Boolean']['output'];
  billingType: BillingType;
  diagnose: Scalars['String']['output'];
  diagnoseId?: Maybe<Scalars['String']['output']>;
  edifactNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  longName: Scalars['String']['output'];
  order: Order;
  partnerLab: PartnerLab;
  price?: Maybe<Scalars['BigDecimal']['output']>;
  shortName: Scalars['String']['output'];
  specialRateCode: Scalars['String']['output'];
  specialRateId?: Maybe<Scalars['String']['output']>;
  specialRateShortName: Scalars['String']['output'];
};

export enum OrderType {
  INITIAL = 'INITIAL',
  REORDER = 'REORDER',
}

export type Pageable = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sortBy: Scalars['String']['input'];
  sortOrder: SortOrder;
};

export type PagedRequests = {
  __typename?: 'PagedRequests';
  entries: Array<Request>;
  totalCount: Scalars['Int']['output'];
};

export type Parameter = {
  __typename?: 'Parameter';
  acute: Scalars['Boolean']['output'];
  billingDescription: Scalars['String']['output'];
  deactivated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  doctorBilling: Scalars['Boolean']['output'];
  edifactNumber?: Maybe<Scalars['Int']['output']>;
  evalHours: Scalars['Int']['output'];
  forms: Array<Form>;
  id: Scalars['ID']['output'];
  insuranceDefinitions: Array<InsuranceDefinition>;
  lab: Lab;
  lgDoctorBilling: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  longName: Scalars['String']['output'];
  partnerLab: PartnerLab;
  patientBilling: Scalars['Boolean']['output'];
  priceDoctor?: Maybe<Scalars['BigDecimal']['output']>;
  priceLgDoctor?: Maybe<Scalars['BigDecimal']['output']>;
  pricePatient?: Maybe<Scalars['BigDecimal']['output']>;
  shortName: Scalars['String']['output'];
  specialRates: Array<SpecialRate>;
  specimens: Array<Specimen>;
  storagePeriod: Scalars['Int']['output'];
  synonyms: Array<Scalars['String']['output']>;
  volume: Scalars['Int']['output'];
  withParameters: Array<Parameter>;
  withoutParameters: Array<Parameter>;
};

export type ParameterCreateInput = {
  acute: Scalars['Boolean']['input'];
  billingDescription: Scalars['String']['input'];
  deactivated: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  doctorBilling: Scalars['Boolean']['input'];
  edifactNumber?: InputMaybe<Scalars['Int']['input']>;
  evalHours: Scalars['Int']['input'];
  formIds: Array<Scalars['String']['input']>;
  insuranceDefinitions: Array<InsuranceDefinitionInput>;
  labId: Scalars['ID']['input'];
  lgDoctorBilling: Scalars['Boolean']['input'];
  link: Scalars['String']['input'];
  longName: Scalars['String']['input'];
  partnerLab: PartnerLab;
  patientBilling: Scalars['Boolean']['input'];
  priceDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceLgDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  pricePatient?: InputMaybe<Scalars['BigDecimal']['input']>;
  shortName: Scalars['String']['input'];
  specialRateIds: Array<Scalars['String']['input']>;
  specimenIds: Array<Scalars['String']['input']>;
  storagePeriod: Scalars['Int']['input'];
  synonyms: Array<Scalars['String']['input']>;
  volume: Scalars['Int']['input'];
  withParameterIds: Array<Scalars['String']['input']>;
  withoutParameterIds: Array<Scalars['String']['input']>;
};

export type ParameterToAdd = {
  __typename?: 'ParameterToAdd';
  billingInfo?: Maybe<BillingInfo>;
  parameterId: Scalars['ID']['output'];
};

export type ParameterToAddInput = {
  parameterId: Scalars['ID']['input'];
  preferredBillingType?: InputMaybe<BillingType>;
  preferredDiagnoseIds: Array<Scalars['String']['input']>;
};

export type ParameterUpdateInput = {
  acute: Scalars['Boolean']['input'];
  billingDescription: Scalars['String']['input'];
  deactivated: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  doctorBilling: Scalars['Boolean']['input'];
  edifactNumber?: InputMaybe<Scalars['Int']['input']>;
  evalHours: Scalars['Int']['input'];
  formIds: Array<Scalars['String']['input']>;
  insuranceDefinitions: Array<InsuranceDefinitionInput>;
  lgDoctorBilling: Scalars['Boolean']['input'];
  link: Scalars['String']['input'];
  longName: Scalars['String']['input'];
  partnerLab: PartnerLab;
  patientBilling: Scalars['Boolean']['input'];
  priceDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceLgDoctor?: InputMaybe<Scalars['BigDecimal']['input']>;
  pricePatient?: InputMaybe<Scalars['BigDecimal']['input']>;
  specialRateIds: Array<Scalars['String']['input']>;
  specimenIds: Array<Scalars['String']['input']>;
  storagePeriod: Scalars['Int']['input'];
  synonyms: Array<Scalars['String']['input']>;
  volume: Scalars['Int']['input'];
  withParameterIds: Array<Scalars['String']['input']>;
  withoutParameterIds: Array<Scalars['String']['input']>;
};

export enum PartnerLab {
  NONE = 'NONE',
  PL1 = 'PL1',
  PL2 = 'PL2',
  PL3 = 'PL3',
}

export type PatientData = {
  __typename?: 'PatientData';
  birthday: Scalars['Date']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  idCardAuthority: Scalars['String']['output'];
  idCardNumber: Scalars['String']['output'];
  idCardType: IdCardType;
  insuranceCategory: InsuranceCategory;
  insuranceCode: Scalars['String']['output'];
  insuredPerson?: Maybe<InsuredPerson>;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  street: Scalars['String']['output'];
  svnr: Scalars['String']['output'];
  title: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type PatientDataInput = {
  birthday: Scalars['Date']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  idCardAuthority: Scalars['String']['input'];
  idCardNumber: Scalars['String']['input'];
  idCardType: IdCardType;
  insuranceCategory: InsuranceCategory;
  insuranceCode: Scalars['String']['input'];
  insuredPerson?: InputMaybe<InsuredPersonInput>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  svnr: Scalars['String']['input'];
  title: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type PatientSearchFilter = {
  birthday?: InputMaybe<Scalars['Date']['input']>;
  search: Scalars['String']['input'];
};

export type PatientSearchResult = {
  __typename?: 'PatientSearchResult';
  entries: Array<Request>;
  exact: Scalars['Boolean']['output'];
};

export enum Period {
  CURRENT_DAY = 'CURRENT_DAY',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_REQUEST = 'CURRENT_REQUEST',
  CURRENT_YEAR = 'CURRENT_YEAR',
}

export type PeriodicGroup = {
  __typename?: 'PeriodicGroup';
  id: Scalars['ID']['output'];
  lab: Lab;
  maxCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  period: Period;
};

export type PeriodicGroupCreateInput = {
  labId: Scalars['ID']['input'];
  maxCount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  period: Period;
};

export type PeriodicGroupUpdateInput = {
  maxCount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  period: Period;
};

export type Profile = {
  __typename?: 'Profile';
  diagnose: Scalars['String']['output'];
  diagnoses: Array<Diagnose>;
  doctor: Doctor;
  hotKey?: Maybe<HotKey>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parameters: Array<ProfileParameter>;
};

export type ProfileInput = {
  diagnose: Scalars['String']['input'];
  diagnoseIds: Array<Scalars['String']['input']>;
  doctorId: Scalars['String']['input'];
  hotKey?: InputMaybe<HotKey>;
  name: Scalars['String']['input'];
  parameters: Array<ProfileParameterInput>;
};

export type ProfileParameter = {
  __typename?: 'ProfileParameter';
  id: Scalars['ID']['output'];
  parameter: Parameter;
  preferredBillingType?: Maybe<BillingType>;
  profile: Profile;
};

export type ProfileParameterInput = {
  parameterId: Scalars['String']['input'];
  preferredBillingType?: InputMaybe<BillingType>;
};

export type Query = {
  __typename?: 'Query';
  assignmentForCurrentUser?: Maybe<UserAssignment>;
  availableBillingInfos?: Maybe<AvailableBillingInfos>;
  catalog?: Maybe<Catalog>;
  catalogParameters?: Maybe<Array<CatalogParameter>>;
  catalogs?: Maybe<Array<Catalog>>;
  checkParameters?: Maybe<CheckParametersResult>;
  dashboardData?: Maybe<DashboardData>;
  diagnoses?: Maybe<Array<Diagnose>>;
  directForms?: Maybe<Array<Form>>;
  doctor?: Maybe<Doctor>;
  doctorSheets?: Maybe<Array<Sheet>>;
  doctors?: Maybe<Array<Doctor>>;
  doctorsForCurrentUser?: Maybe<Array<Doctor>>;
  importedDoctors?: Maybe<Array<ImportedDoctor>>;
  individualCatalogParameters?: Maybe<Array<CatalogParameter>>;
  insurances?: Maybe<Array<Insurance>>;
  isCurrentUserAuthorizedForDoctor?: Maybe<Scalars['Boolean']['output']>;
  lab?: Maybe<Lab>;
  labCatalogs?: Maybe<Array<Catalog>>;
  labDiagnoses?: Maybe<Array<Diagnose>>;
  labForms?: Maybe<Array<Form>>;
  labParameter?: Maybe<Parameter>;
  labParameters?: Maybe<Array<Parameter>>;
  labPeriodicGroups?: Maybe<Array<PeriodicGroup>>;
  labSheet?: Maybe<Sheet>;
  labSpecialRates?: Maybe<Array<SpecialRate>>;
  labSpecimens?: Maybe<Array<Specimen>>;
  labTestTubes?: Maybe<Array<TestTube>>;
  labs?: Maybe<Array<Lab>>;
  openFrontDeskRequests?: Maybe<Array<Request>>;
  openRequests?: Maybe<Array<Request>>;
  patientRequests?: Maybe<Array<Request>>;
  patientSearch?: Maybe<PatientSearchResult>;
  profiles?: Maybe<Array<Profile>>;
  request?: Maybe<Request>;
  requestCopy?: Maybe<RequestCopy>;
  requestableParameters?: Maybe<Array<RequestableParameter>>;
  requiredForms?: Maybe<Array<Form>>;
  sentRequests?: Maybe<PagedRequests>;
  specialRates?: Maybe<Array<SpecialRate>>;
  systemStatus?: Maybe<SystemStatus>;
  testTube?: Maybe<TestTube>;
  themes?: Maybe<Array<Theme>>;
  users?: Maybe<UserResult>;
};

export type QueryAvailableBillingInfosArgs = {
  catalogParamId: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
  patientData: PatientDataInput;
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
  selectedParameters: Array<SelectedParameterInput>;
};

export type QueryCatalogArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCatalogParametersArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryCheckParametersArgs = {
  doctorId: Scalars['ID']['input'];
  patientData: PatientDataInput;
  selectedParameters: Array<SelectedParameterInput>;
};

export type QueryDashboardDataArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryDiagnosesArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryDirectFormsArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryDoctorArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDoctorSheetsArgs = {
  doctorId: Scalars['ID']['input'];
  withLabSheets: Scalars['Boolean']['input'];
};

export type QueryIndividualCatalogParametersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryIsCurrentUserAuthorizedForDoctorArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryLabArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLabCatalogsArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabDiagnosesArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabFormsArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabParameterArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLabParametersArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabPeriodicGroupsArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabSheetArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabSpecialRatesArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabSpecimensArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryLabTestTubesArgs = {
  labId: Scalars['ID']['input'];
};

export type QueryOpenRequestsArgs = {
  doctorId: Scalars['ID']['input'];
  svnr?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPatientRequestsArgs = {
  doctorId: Scalars['ID']['input'];
  svnr: Scalars['String']['input'];
};

export type QueryPatientSearchArgs = {
  filter: PatientSearchFilter;
};

export type QueryProfilesArgs = {
  doctorId: Scalars['ID']['input'];
};

export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRequestCopyArgs = {
  currentPatientData?: InputMaybe<PatientDataInput>;
  requestId: Scalars['ID']['input'];
};

export type QueryRequestableParametersArgs = {
  doctorId: Scalars['ID']['input'];
  patientData: PatientDataInput;
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRequiredFormsArgs = {
  catalogParamIds: Array<Scalars['ID']['input']>;
  doctorId: Scalars['ID']['input'];
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySentRequestsArgs = {
  doctorId: Scalars['ID']['input'];
  filter: RequestFilter;
  pageable: Pageable;
};

export type QueryTestTubeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  doctorId?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ReorderInput = {
  acute: Scalars['Boolean']['input'];
  diagnose: Scalars['String']['input'];
  formFieldValues: Array<FormFieldValuesInput>;
  labInfo: Scalars['String']['input'];
  localizations: Array<LocalizationInput>;
  requestId: Scalars['ID']['input'];
  selectedParameters: Array<SelectedParameterInput>;
};

export type Request = {
  __typename?: 'Request';
  additionalData: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdByFirstName: Scalars['String']['output'];
  createdByFrontDesk: Scalars['Boolean']['output'];
  createdByLastName: Scalars['String']['output'];
  csvImportHash?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  doctor: Doctor;
  id: Scalars['ID']['output'];
  importRequestId?: Maybe<Scalars['String']['output']>;
  importType?: Maybe<ImportType>;
  insuranceName: Scalars['String']['output'];
  insuranceShortName: Scalars['String']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedByFirstName: Scalars['String']['output'];
  modifiedByLastName: Scalars['String']['output'];
  orders: Array<Order>;
  patientData: PatientData;
  reorderable: Scalars['Boolean']['output'];
  reorderableUntil?: Maybe<Scalars['DateTime']['output']>;
  sampledAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentByFirstName: Scalars['String']['output'];
  sentByLastName: Scalars['String']['output'];
  status: RequestStatus;
};

export type RequestCopy = {
  __typename?: 'RequestCopy';
  acute: Scalars['Boolean']['output'];
  additionalData: Scalars['JSON']['output'];
  diagnose: Scalars['String']['output'];
  labInfo: Scalars['String']['output'];
  localizations: Array<Localization>;
  orderForms: Array<OrderForm>;
  patientData: PatientData;
  sampledAt?: Maybe<Scalars['DateTime']['output']>;
  selectedParameters: Array<SelectedParameter>;
  unavailableParameters: Array<Scalars['String']['output']>;
  unknownBillingParameters: Array<UnknownBillingParameter>;
};

export type RequestFilter = {
  birthday?: InputMaybe<Scalars['Date']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  search: Scalars['String']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
};

export enum RequestStatus {
  NEW = 'NEW',
  PRINTED = 'PRINTED',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export type RequestableParameter = {
  __typename?: 'RequestableParameter';
  acute: Scalars['Boolean']['output'];
  billingDescription: Scalars['String']['output'];
  billingInfos: Array<BillingInfo>;
  description: Scalars['String']['output'];
  evalHours: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lab: Lab;
  link: Scalars['String']['output'];
  longName: Scalars['String']['output'];
  mainParameterId: Scalars['ID']['output'];
  pricePatient?: Maybe<Scalars['BigDecimal']['output']>;
  shortName: Scalars['String']['output'];
  specimens: Array<RequestableSpecimen>;
  synonyms: Array<Scalars['String']['output']>;
  volume: Scalars['Int']['output'];
  withParameterNames: Array<Scalars['String']['output']>;
  withoutParameterIds: Array<Scalars['ID']['output']>;
  withoutParameterNames: Array<Scalars['String']['output']>;
};

export type RequestableSpecimen = {
  __typename?: 'RequestableSpecimen';
  classification: Scalars['String']['output'];
  group: SpecimenGroup;
  id: Scalars['ID']['output'];
  localizationInputEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  predefinedLocalizations: Array<Scalars['String']['output']>;
  requiresLocalizations: Scalars['Boolean']['output'];
  storagePeriod: Scalars['Int']['output'];
  testTubes: Array<TestTube>;
};

export type SelectedParameter = {
  __typename?: 'SelectedParameter';
  billingInfo: BillingInfo;
  requestableParameter: RequestableParameter;
};

export type SelectedParameterInput = {
  billingType: BillingType;
  diagnoseId?: InputMaybe<Scalars['String']['input']>;
  parameterId: Scalars['ID']['input'];
  parameterLongName: Scalars['String']['input'];
  parameterShortName: Scalars['String']['input'];
  specialRateId?: InputMaybe<Scalars['String']['input']>;
};

export type Sheet = {
  __typename?: 'Sheet';
  data: Scalars['JSON']['output'];
  doctorId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lab?: Maybe<Lab>;
  labId?: Maybe<Scalars['String']['output']>;
};

export type SheetInput = {
  data: Scalars['JSON']['input'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SpecialRate = {
  __typename?: 'SpecialRate';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lab: Lab;
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type SpecialRateCreateInput = {
  code: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  shortName: Scalars['String']['input'];
};

export type SpecialRateUpdateInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shortName: Scalars['String']['input'];
};

export type Specimen = {
  __typename?: 'Specimen';
  classification: Scalars['String']['output'];
  features: Array<SpecimenFeature>;
  forms: Array<Form>;
  group: SpecimenGroup;
  id: Scalars['ID']['output'];
  lab: Lab;
  localizationInputEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  predefinedLocalizations: Array<Scalars['String']['output']>;
  requiresLocalizations: Scalars['Boolean']['output'];
  storagePeriod: Scalars['Int']['output'];
  testTubes: Array<TestTube>;
};

export type SpecimenCreateInput = {
  classification: Scalars['String']['input'];
  features: Array<SpecimenFeature>;
  formIds: Array<Scalars['String']['input']>;
  group: SpecimenGroup;
  labId: Scalars['ID']['input'];
  localizationInputEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  predefinedLocalizations: Array<Scalars['String']['input']>;
  requiresLocalizations: Scalars['Boolean']['input'];
  storagePeriod: Scalars['Int']['input'];
  testTubeIds: Array<Scalars['String']['input']>;
};

export enum SpecimenFeature {
  CENTRIFUGED = 'CENTRIFUGED',
  COOLED = 'COOLED',
  FROZEN = 'FROZEN',
  UV_PROTECTED = 'UV_PROTECTED',
}

export enum SpecimenGroup {
  HARN = 'HARN',
  NONE = 'NONE',
  SERUM = 'SERUM',
}

export type SpecimenUpdateInput = {
  classification: Scalars['String']['input'];
  features: Array<SpecimenFeature>;
  formIds: Array<Scalars['String']['input']>;
  group: SpecimenGroup;
  localizationInputEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  predefinedLocalizations: Array<Scalars['String']['input']>;
  requiresLocalizations: Scalars['Boolean']['input'];
  storagePeriod: Scalars['Int']['input'];
  testTubeIds: Array<Scalars['String']['input']>;
};

export type SystemStatus = {
  __typename?: 'SystemStatus';
  active: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  type: SystemStatusType;
};

export type SystemStatusInput = {
  active: Scalars['Boolean']['input'];
  content: Scalars['String']['input'];
  heading: Scalars['String']['input'];
  link: Scalars['String']['input'];
  type: SystemStatusType;
};

export enum SystemStatusType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type TestTube = {
  __typename?: 'TestTube';
  color: TestTubeColor;
  hasImage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lab: Lab;
  labelText: Scalars['String']['output'];
  labelText2: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  supplier: TestTubeSupplier;
  volume: Scalars['Int']['output'];
};

export enum TestTubeColor {
  BLACK = 'BLACK',
  BROWN = 'BROWN',
  CYAN = 'CYAN',
  DARKBLUE = 'DARKBLUE',
  DARKBROWN = 'DARKBROWN',
  GREEN = 'GREEN',
  GREY = 'GREY',
  LIGHTBLUE = 'LIGHTBLUE',
  NONE = 'NONE',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  RED = 'RED',
  VIOLET = 'VIOLET',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

export type TestTubeCreateInput = {
  color: TestTubeColor;
  labId: Scalars['ID']['input'];
  labelText: Scalars['String']['input'];
  labelText2: Scalars['String']['input'];
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  supplier: TestTubeSupplier;
  volume: Scalars['Int']['input'];
};

export enum TestTubeSupplier {
  SARSTEDT = 'SARSTEDT',
  VACUETTE = 'VACUETTE',
}

export type TestTubeUpdateInput = {
  color: TestTubeColor;
  labelText: Scalars['String']['input'];
  labelText2: Scalars['String']['input'];
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  supplier: TestTubeSupplier;
  volume: Scalars['Int']['input'];
};

export type Theme = {
  __typename?: 'Theme';
  colorBgHeader: Scalars['String']['output'];
  colorBgTrigger: Scalars['String']['output'];
  colorPrimary: Scalars['String']['output'];
  controlItemBgActive: Scalars['String']['output'];
  controlItemBgActiveHover: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logoData: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ThemeInput = {
  colorBgHeader: Scalars['String']['input'];
  colorBgTrigger: Scalars['String']['input'];
  colorPrimary: Scalars['String']['input'];
  controlItemBgActive: Scalars['String']['input'];
  controlItemBgActiveHover: Scalars['String']['input'];
  logoData: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UnknownBillingParameter = {
  __typename?: 'UnknownBillingParameter';
  id: Scalars['ID']['output'];
  longName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type UpdateRequestInput = {
  acute: Scalars['Boolean']['input'];
  additionalData: Scalars['JSON']['input'];
  diagnose: Scalars['String']['input'];
  formFieldValues: Array<FormFieldValuesInput>;
  labInfo: Scalars['String']['input'];
  localizations: Array<LocalizationInput>;
  patientData: PatientDataInput;
  requestId: Scalars['ID']['input'];
  sampledAt?: InputMaybe<Scalars['DateTime']['input']>;
  selectedParameters: Array<SelectedParameterInput>;
};

export type User = {
  __typename?: 'User';
  disabled: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  userAssignment?: Maybe<UserAssignment>;
  username: Scalars['String']['output'];
};

export type UserAssignment = {
  __typename?: 'UserAssignment';
  additionalDoctors: Array<Doctor>;
  id: Scalars['ID']['output'];
  primaryDoctor: Doctor;
  userId: Scalars['String']['output'];
};

export type UserAssignmentInput = {
  additionalDoctorIds: Array<Scalars['String']['input']>;
  primaryDoctorId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserResult = {
  __typename?: 'UserResult';
  items: Array<User>;
  length: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AssignmentForCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type AssignmentForCurrentUserQuery = {
  __typename?: 'Query';
  assignmentForCurrentUser?: {
    __typename?: 'UserAssignment';
    id: string;
    primaryDoctor: { __typename?: 'Doctor'; id: string };
  } | null;
};

export type UserDoctorAuthCheckQueryQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type UserDoctorAuthCheckQueryQuery = { __typename?: 'Query'; isCurrentUserAuthorizedForDoctor?: boolean | null };

export type SystemStatusWrapperQueryVariables = Exact<{ [key: string]: never }>;

export type SystemStatusWrapperQuery = {
  __typename?: 'Query';
  systemStatus?: {
    __typename?: 'SystemStatus';
    id: string;
    active: boolean;
    heading: string;
    content: string;
    link: string;
    type: SystemStatusType;
  } | null;
};

export type SheetEditor_ParameterFragment = {
  __typename?: 'Parameter';
  id: string;
  shortName: string;
  longName: string;
  deactivated: boolean;
  synonyms: Array<string>;
  lab: { __typename?: 'Lab'; id: string; shortName: string };
} & { ' $fragmentName'?: 'SheetEditor_ParameterFragment' };

export type MarkAisExportedMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;

export type MarkAisExportedMutation = {
  __typename?: 'Mutation';
  markAisExported?: { __typename?: 'Request'; id: string } | null;
};

export type CheckParametersQueryVariables = Exact<{
  selectedParameters: Array<SelectedParameterInput> | SelectedParameterInput;
  patientData: PatientDataInput;
  doctorId: Scalars['ID']['input'];
}>;

export type CheckParametersQuery = {
  __typename?: 'Query';
  checkParameters?: {
    __typename?: 'CheckParametersResult';
    unavailableParameters: Array<string>;
    selectedParameters: Array<{
      __typename?: 'SelectedParameter';
      requestableParameter: {
        __typename?: 'RequestableParameter';
        id: string;
        mainParameterId: string;
        shortName: string;
        longName: string;
        withoutParameterIds: Array<string>;
        acute: boolean;
        volume: number;
        link: string;
        description: string;
        billingDescription: string;
        evalHours: number;
        synonyms: Array<string>;
        pricePatient?: number | null;
        withParameterNames: Array<string>;
        withoutParameterNames: Array<string>;
        billingInfos: Array<{
          __typename?: 'BillingInfo';
          billingType: BillingType;
          price?: number | null;
          text: string;
          additionalText: string;
          specialRateId?: string | null;
          diagnoseId?: string | null;
        }>;
        specimens: Array<{
          __typename?: 'RequestableSpecimen';
          id: string;
          classification: string;
          name: string;
          storagePeriod: number;
          requiresLocalizations: boolean;
          localizationInputEnabled: boolean;
          predefinedLocalizations: Array<string>;
          testTubes: Array<{
            __typename?: 'TestTube';
            id: string;
            color: TestTubeColor;
            name: string;
            hasImage: boolean;
            labelText: string;
            labelText2: string;
            link: string;
            volume: number;
          }>;
        }>;
        lab: { __typename?: 'Lab'; id: string; name: string; shortName: string; diagnoseRequired: boolean };
      };
      billingInfo: {
        __typename?: 'BillingInfo';
        additionalText: string;
        billingType: BillingType;
        diagnoseId?: string | null;
        text: string;
        price?: number | null;
        specialRateId?: string | null;
      };
    }>;
    unknownBillingParameters: Array<{
      __typename?: 'UnknownBillingParameter';
      id: string;
      shortName: string;
      longName: string;
    }>;
  } | null;
};

export type DoctorAisExportTypesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DoctorAisExportTypesQuery = {
  __typename?: 'Query';
  doctor?: { __typename?: 'Doctor'; id: string; aisExportTypes: Array<AisExportType> } | null;
};

export type DoctorHasAisQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DoctorHasAisQuery = {
  __typename?: 'Query';
  doctor?: { __typename?: 'Doctor'; id: string; hasAis: boolean } | null;
};

export type DoctorThemeQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DoctorThemeQuery = {
  __typename?: 'Query';
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    theme?: {
      __typename?: 'Theme';
      id: string;
      name: string;
      colorBgHeader: string;
      colorBgTrigger: string;
      colorPrimary: string;
      controlItemBgActive: string;
      controlItemBgActiveHover: string;
      logoData: string;
    } | null;
  } | null;
};

export type LabSheetQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type LabSheetQuery = {
  __typename?: 'Query';
  labSheet?: { __typename?: 'Sheet'; id: string; data: any } | null;
  labParameters?: Array<
    { __typename?: 'Parameter' } & {
      ' $fragmentRefs'?: { SheetEditor_ParameterFragment: SheetEditor_ParameterFragment };
    }
  > | null;
};

export type SaveLabSheetMutationVariables = Exact<{
  labId: Scalars['ID']['input'];
  input: SheetInput;
}>;

export type SaveLabSheetMutation = {
  __typename?: 'Mutation';
  saveLabSheet?: { __typename?: 'Sheet'; id: string } | null;
};

export type MarkPrintedMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
  beforeSend: Scalars['Boolean']['input'];
}>;

export type MarkPrintedMutation = {
  __typename?: 'Mutation';
  markPrinted?: { __typename?: 'Request'; id: string } | null;
};

export type RequestCopyQueryVariables = Exact<{
  requestId: Scalars['ID']['input'];
  currentPatientData?: InputMaybe<PatientDataInput>;
}>;

export type RequestCopyQuery = {
  __typename?: 'Query';
  requestCopy?:
    | ({ __typename?: 'RequestCopy' } & { ' $fragmentRefs'?: { RequestCopyDataFragment: RequestCopyDataFragment } })
    | null;
};

export type RequestCopyDataFragment = {
  __typename?: 'RequestCopy';
  labInfo: string;
  diagnose: string;
  acute: boolean;
  sampledAt?: string | null;
  additionalData: any;
  unavailableParameters: Array<string>;
  patientData: {
    __typename?: 'PatientData';
    birthday: string;
    city: string;
    country: string;
    email: string;
    externalId: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    zip: string;
    svnr: string;
    title: string;
    street: string;
    phone: string;
    insuranceCode: string;
    idCardAuthority: string;
    idCardNumber: string;
    idCardType: IdCardType;
    insuranceCategory: InsuranceCategory;
    insuredPerson?: {
      __typename?: 'InsuredPerson';
      phone: string;
      street: string;
      title: string;
      svnr: string;
      zip: string;
      birthday: string;
      gender?: Gender | null;
      firstName: string;
      email: string;
      country: string;
      city: string;
      lastName: string;
    } | null;
  };
  selectedParameters: Array<{
    __typename?: 'SelectedParameter';
    requestableParameter: {
      __typename?: 'RequestableParameter';
      id: string;
      mainParameterId: string;
      shortName: string;
      longName: string;
      withoutParameterIds: Array<string>;
      withoutParameterNames: Array<string>;
      withParameterNames: Array<string>;
      pricePatient?: number | null;
      link: string;
      description: string;
      billingDescription: string;
      evalHours: number;
      synonyms: Array<string>;
      acute: boolean;
      volume: number;
      billingInfos: Array<{
        __typename?: 'BillingInfo';
        billingType: BillingType;
        price?: number | null;
        text: string;
        additionalText: string;
        specialRateId?: string | null;
        diagnoseId?: string | null;
      }>;
      specimens: Array<{
        __typename?: 'RequestableSpecimen';
        id: string;
        classification: string;
        name: string;
        storagePeriod: number;
        requiresLocalizations: boolean;
        predefinedLocalizations: Array<string>;
        localizationInputEnabled: boolean;
        testTubes: Array<{
          __typename?: 'TestTube';
          id: string;
          color: TestTubeColor;
          name: string;
          hasImage: boolean;
          labelText: string;
          labelText2: string;
          link: string;
          volume: number;
        }>;
      }>;
      lab: { __typename?: 'Lab'; id: string; name: string; shortName: string; diagnoseRequired: boolean };
    };
    billingInfo: {
      __typename?: 'BillingInfo';
      additionalText: string;
      billingType: BillingType;
      diagnoseId?: string | null;
      text: string;
      price?: number | null;
      specialRateId?: string | null;
    };
  }>;
  unknownBillingParameters: Array<{
    __typename?: 'UnknownBillingParameter';
    id: string;
    shortName: string;
    longName: string;
  }>;
  orderForms: Array<{
    __typename?: 'OrderForm';
    id: string;
    restore: boolean;
    fields: Array<{
      __typename?: 'OrderFormField';
      id: string;
      values: Array<string>;
      type: FormFieldType;
      formFieldId: string;
    }>;
  }>;
  localizations: Array<{ __typename?: 'Localization'; paramId: string; specimenId: string; location: string }>;
} & { ' $fragmentName'?: 'RequestCopyDataFragment' };

export type SendRequestsMutationVariables = Exact<{
  requestIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type SendRequestsMutation = {
  __typename?: 'Mutation';
  sendRequests?: Array<{
    __typename?: 'Request';
    id: string;
    additionalData: any;
    orders: Array<{
      __typename?: 'Order';
      aisExportedAt?: string | null;
      orderFiles: Array<{
        __typename?: 'OrderFile';
        id: string;
        printedAt?: string | null;
        printable: boolean;
        printRequired: boolean;
        printBeforeSend: boolean;
        printCopies: number;
      }>;
      orderLabels: Array<{
        __typename?: 'OrderLabel';
        id: string;
        printedAt?: string | null;
        specimenId: string;
        testTubeId: string;
        location: string;
      }>;
    }>;
  }> | null;
};

export type FrontDeskDoctorQueryVariables = Exact<{ [key: string]: never }>;

export type FrontDeskDoctorQuery = {
  __typename?: 'Query';
  assignmentForCurrentUser?: {
    __typename?: 'UserAssignment';
    id: string;
    primaryDoctor: {
      __typename?: 'Doctor';
      id: string;
      assignedLabs: Array<{
        __typename?: 'AssignedLab';
        lab: { __typename?: 'Lab'; id: string; name: string; identifier: string };
      }>;
    };
  } | null;
  importedDoctors?: Array<{
    __typename?: 'ImportedDoctor';
    id: string;
    labId: string;
    salutation: DoctorSalutation;
    name: string;
    specialist: string;
    hvNumber: string;
    customerId: string;
    meAddress: string;
    firstName: string;
    lastName: string;
    preTitle: string;
    postTitle: string;
    street: string;
    zip: string;
    city: string;
    email: string;
    phone: string;
    comment: string;
  }> | null;
};

export type CreateFrontDeskDoctorMutationVariables = Exact<{
  input: CreateDoctorFrontDeskInput;
}>;

export type CreateFrontDeskDoctorMutation = {
  __typename?: 'Mutation';
  createDoctorFrontDesk?: { __typename?: 'Doctor'; id: string } | null;
};

export type DashboardDataQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DashboardDataQuery = {
  __typename?: 'Query';
  dashboardData?: {
    __typename?: 'DashboardData';
    openRequests: number;
    sentRequestsToday: number;
    sentRequestsWeek: number;
    sentRequestsMonth: number;
    sentRequestsYear: number;
  } | null;
};

export type DoctorSearchQueryVariables = Exact<{ [key: string]: never }>;

export type DoctorSearchQuery = {
  __typename?: 'Query';
  doctorsForCurrentUser?: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    zip: string;
    city: string;
    meAddress: string;
    disabled: boolean;
    assignedLabs: Array<{
      __typename?: 'AssignedLab';
      customerId: string;
      lab: { __typename?: 'Lab'; id: string; shortName: string };
    }>;
  }> | null;
};

export type FibuExportQueryVariables = Exact<{ [key: string]: never }>;

export type FibuExportQuery = {
  __typename?: 'Query';
  assignmentForCurrentUser?: {
    __typename?: 'UserAssignment';
    id: string;
    primaryDoctor: {
      __typename?: 'Doctor';
      id: string;
      assignedLabs: Array<{
        __typename?: 'AssignedLab';
        lab: { __typename?: 'Lab'; id: string; name: string; identifier: string };
      }>;
    };
  } | null;
};

export type OpenFrontDeskRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type OpenFrontDeskRequestsQuery = {
  __typename?: 'Query';
  openFrontDeskRequests?: Array<{
    __typename?: 'Request';
    id: string;
    patientData: { __typename?: 'PatientData'; title: string; firstName: string; lastName: string; svnr: string };
    doctor: {
      __typename?: 'Doctor';
      id: string;
      name: string;
      meAddress: string;
      assignedLabs: Array<{ __typename?: 'AssignedLab'; customerId: string }>;
    };
    orders: Array<{ __typename?: 'Order'; id: string; number: string }>;
  }> | null;
};

export type OpenRequestsQueryVariables = Exact<{
  currentDoctorId: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
}>;

export type OpenRequestsQuery = {
  __typename?: 'Query';
  openRequests?: Array<{
    __typename?: 'Request';
    id: string;
    createdAt: string;
    modifiedAt?: string | null;
    sampledAt?: string | null;
    status: RequestStatus;
    createdByFirstName: string;
    createdByLastName: string;
    modifiedByFirstName: string;
    modifiedByLastName: string;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      number: string;
      lab: { __typename?: 'Lab'; id: string; shortName: string };
      orderLabels: Array<{
        __typename?: 'OrderLabel';
        id: string;
        printedAt?: string | null;
        specimenId: string;
        testTubeId: string;
        location: string;
      }>;
      orderFiles: Array<{
        __typename?: 'OrderFile';
        id: string;
        printable: boolean;
        printRequired: boolean;
        printBeforeSend: boolean;
        printCopies: number;
        printedAt?: string | null;
      }>;
    }>;
    patientData: {
      __typename?: 'PatientData';
      birthday: string;
      city: string;
      country: string;
      email: string;
      externalId: string;
      firstName: string;
      lastName: string;
      gender: Gender;
      zip: string;
      svnr: string;
      title: string;
      street: string;
      phone: string;
      insuranceCode: string;
      idCardAuthority: string;
      idCardNumber: string;
      idCardType: IdCardType;
      insuranceCategory: InsuranceCategory;
      insuredPerson?: {
        __typename?: 'InsuredPerson';
        phone: string;
        street: string;
        title: string;
        svnr: string;
        zip: string;
        birthday: string;
        gender?: Gender | null;
        firstName: string;
        email: string;
        country: string;
        city: string;
        lastName: string;
      } | null;
    };
  }> | null;
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    flipParamNames: boolean;
    addEmptyLabels: number;
    csvImportEnabled: boolean;
  } | null;
};

export type DeleteAllOpenRequestsMutationVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DeleteAllOpenRequestsMutation = { __typename?: 'Mutation'; deleteAllOpenRequests?: boolean | null };

export type DeleteOpenRequestMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;

export type DeleteOpenRequestMutation = { __typename?: 'Mutation'; deleteOpenRequest?: boolean | null };

export type PatientSearchQueryVariables = Exact<{
  filter: PatientSearchFilter;
}>;

export type PatientSearchQuery = {
  __typename?: 'Query';
  patientSearch?: {
    __typename?: 'PatientSearchResult';
    exact: boolean;
    entries: Array<{
      __typename?: 'Request';
      id: string;
      insuranceShortName: string;
      createdAt: string;
      patientData: {
        __typename?: 'PatientData';
        birthday: string;
        city: string;
        country: string;
        firstName: string;
        lastName: string;
        zip: string;
        svnr: string;
        title: string;
        street: string;
        email: string;
        gender: Gender;
        phone: string;
        externalId: string;
        insuranceCode: string;
        insuranceCategory: InsuranceCategory;
        idCardType: IdCardType;
        idCardNumber: string;
        idCardAuthority: string;
        insuredPerson?: {
          __typename?: 'InsuredPerson';
          title: string;
          firstName: string;
          lastName: string;
          birthday: string;
          gender?: Gender | null;
          svnr: string;
          city: string;
          zip: string;
          street: string;
          country: string;
          phone: string;
          email: string;
        } | null;
      };
    }>;
  } | null;
};

export type SentRequestsQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
  pageable: Pageable;
  filter: RequestFilter;
}>;

export type SentRequestsQuery = {
  __typename?: 'Query';
  sentRequests?: {
    __typename?: 'PagedRequests';
    totalCount: number;
    entries: Array<{
      __typename?: 'Request';
      id: string;
      createdAt: string;
      sentAt?: string | null;
      sampledAt?: string | null;
      status: RequestStatus;
      reorderable: boolean;
      reorderableUntil?: string | null;
      createdByFirstName: string;
      createdByLastName: string;
      modifiedByFirstName: string;
      modifiedByLastName: string;
      sentByFirstName: string;
      sentByLastName: string;
      orders: Array<{
        __typename?: 'Order';
        id: string;
        type: OrderType;
        number: string;
        createdAt: string;
        lab: { __typename?: 'Lab'; id: string; shortName: string; identifier: string };
      }>;
      patientData: {
        __typename?: 'PatientData';
        birthday: string;
        city: string;
        country: string;
        email: string;
        externalId: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        zip: string;
        svnr: string;
        title: string;
        street: string;
        phone: string;
        insuranceCode: string;
        idCardAuthority: string;
        idCardNumber: string;
        idCardType: IdCardType;
        insuranceCategory: InsuranceCategory;
        insuredPerson?: {
          __typename?: 'InsuredPerson';
          phone: string;
          street: string;
          title: string;
          svnr: string;
          zip: string;
          birthday: string;
          gender?: Gender | null;
          firstName: string;
          email: string;
          country: string;
          city: string;
          lastName: string;
        } | null;
      };
    }>;
  } | null;
  doctor?: { __typename?: 'Doctor'; id: string; flipParamNames: boolean } | null;
};

export type DoctorsQueryVariables = Exact<{ [key: string]: never }>;

export type DoctorsQuery = {
  __typename?: 'Query';
  doctors?: Array<
    { __typename?: 'Doctor' } & {
      ' $fragmentRefs'?: { UpdateDoctorModal_DoctorFragment: UpdateDoctorModal_DoctorFragment };
    }
  > | null;
  labs?: Array<
    { __typename?: 'Lab' } & { ' $fragmentRefs'?: { DoctorForm_LabFragment: DoctorForm_LabFragment } }
  > | null;
  catalogs?: Array<
    { __typename?: 'Catalog' } & { ' $fragmentRefs'?: { DoctorForm_CatalogFragment: DoctorForm_CatalogFragment } }
  > | null;
  insurances?: Array<
    { __typename?: 'Insurance' } & { ' $fragmentRefs'?: { DoctorForm_InsuranceFragment: DoctorForm_InsuranceFragment } }
  > | null;
  specialRates?: Array<
    { __typename?: 'SpecialRate' } & {
      ' $fragmentRefs'?: { DoctorForm_SpecialRateFragment: DoctorForm_SpecialRateFragment };
    }
  > | null;
  themes?: Array<
    { __typename?: 'Theme' } & { ' $fragmentRefs'?: { DoctorForm_ThemeFragment: DoctorForm_ThemeFragment } }
  > | null;
};

export type InsurancesQueryVariables = Exact<{ [key: string]: never }>;

export type InsurancesQuery = {
  __typename?: 'Query';
  insurances?: Array<{
    __typename?: 'Insurance';
    id: string;
    name: string;
    shortName: string;
    code: string;
    mappings: Array<string>;
  }> | null;
};

export type LabQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LabQuery = {
  __typename?: 'Query';
  lab?: { __typename?: 'Lab'; id: string; name: string; shortName: string; longName: string } | null;
};

export type CompileParamsMutationVariables = Exact<{ [key: string]: never }>;

export type CompileParamsMutation = { __typename?: 'Mutation'; compileParams?: number | null };

export type LabsQueryVariables = Exact<{ [key: string]: never }>;

export type LabsQuery = {
  __typename?: 'Query';
  labs?: Array<{ __typename?: 'Lab'; id: string; name: string; shortName: string; longName: string }> | null;
};

export type UpdateSystemStatusMutationVariables = Exact<{
  input: SystemStatusInput;
}>;

export type UpdateSystemStatusMutation = {
  __typename?: 'Mutation';
  updateSystemStatus?: { __typename?: 'SystemStatus'; id: string } | null;
};

export type SystemStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SystemStatusQuery = {
  __typename?: 'Query';
  systemStatus?: {
    __typename?: 'SystemStatus';
    id: string;
    active: boolean;
    heading: string;
    content: string;
    link: string;
    type: SystemStatusType;
  } | null;
};

export type ThemesQueryVariables = Exact<{ [key: string]: never }>;

export type ThemesQuery = {
  __typename?: 'Query';
  themes?: Array<{
    __typename?: 'Theme';
    id: string;
    name: string;
    colorPrimary: string;
    controlItemBgActive: string;
    controlItemBgActiveHover: string;
    colorBgHeader: string;
    colorBgTrigger: string;
    logoData: string;
  }> | null;
};

export type DoctorsForAssignmentQueryVariables = Exact<{ [key: string]: never }>;

export type DoctorsForAssignmentQuery = {
  __typename?: 'Query';
  doctors?: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    meAddress: string;
    firstName: string;
    lastName: string;
    postTitle: string;
    preTitle: string;
    salutation: DoctorSalutation;
    disabled: boolean;
  }> | null;
};

export type UsersQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  doctorId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UserResult';
    total: number;
    limit: number;
    start: number;
    length: number;
    items: Array<{
      __typename?: 'User';
      id: string;
      username: string;
      email: string;
      firstName: string;
      lastName: string;
      disabled: boolean;
      userAssignment?: {
        __typename?: 'UserAssignment';
        id: string;
        primaryDoctor: {
          __typename?: 'Doctor';
          id: string;
          name: string;
          salutation: DoctorSalutation;
          preTitle: string;
          firstName: string;
          lastName: string;
          postTitle: string;
          disabled: boolean;
        };
        additionalDoctors: Array<{
          __typename?: 'Doctor';
          id: string;
          name: string;
          salutation: DoctorSalutation;
          preTitle: string;
          firstName: string;
          lastName: string;
          postTitle: string;
          disabled: boolean;
        }>;
      } | null;
    }>;
  } | null;
};

export type DeleteUserAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserAssignmentMutation = { __typename?: 'Mutation'; deleteUserAssignment?: boolean | null };

export type CreateDoctorMutationVariables = Exact<{
  input: DoctorInput;
}>;

export type CreateDoctorMutation = {
  __typename?: 'Mutation';
  createDoctor?: { __typename?: 'Doctor'; id: string } | null;
};

export type DoctorForm_LabFragment = { __typename?: 'Lab'; id: string; name: string } & {
  ' $fragmentName'?: 'DoctorForm_LabFragment';
};

export type DoctorForm_ThemeFragment = { __typename?: 'Theme'; id: string; name: string } & {
  ' $fragmentName'?: 'DoctorForm_ThemeFragment';
};

export type DoctorForm_CatalogFragment = {
  __typename?: 'Catalog';
  id: string;
  name: string;
  lab: { __typename?: 'Lab'; id: string; shortName: string };
} & { ' $fragmentName'?: 'DoctorForm_CatalogFragment' };

export type DoctorForm_InsuranceFragment = {
  __typename?: 'Insurance';
  id: string;
  name: string;
  shortName: string;
  code: string;
} & { ' $fragmentName'?: 'DoctorForm_InsuranceFragment' };

export type DoctorForm_SpecialRateFragment = {
  __typename?: 'SpecialRate';
  id: string;
  name: string;
  shortName: string;
  lab: { __typename?: 'Lab'; id: string; shortName: string };
} & { ' $fragmentName'?: 'DoctorForm_SpecialRateFragment' };

export type ImportLookupDoctorQueryVariables = Exact<{ [key: string]: never }>;

export type ImportLookupDoctorQuery = {
  __typename?: 'Query';
  labs?: Array<{ __typename?: 'Lab'; id: string; identifier: string; name: string }> | null;
};

export type UpdateDoctorMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DoctorInput;
}>;

export type UpdateDoctorMutation = {
  __typename?: 'Mutation';
  updateDoctor?: { __typename?: 'Doctor'; id: string } | null;
};

export type UpdateDoctorModal_DoctorFragment = {
  __typename?: 'Doctor';
  id: string;
  salutation: DoctorSalutation;
  preTitle: string;
  postTitle: string;
  firstName: string;
  lastName: string;
  name: string;
  specialist: string;
  meAddress: string;
  hvNumber: string;
  street: string;
  zip: string;
  city: string;
  email: string;
  phone: string;
  comment: string;
  aisImportType: AisImportType;
  aisImportDoctorId: string;
  billingPreference: BillingPreference;
  preferSpecialRate: boolean;
  autoDiagnose: boolean;
  autoSpecialRate: boolean;
  doctorBilling: boolean;
  patientBilling: boolean;
  lgDoctorBilling: boolean;
  hasAis: boolean;
  printTransferDocument: boolean;
  showPrices: boolean;
  showPriceSuggestion: boolean;
  flipParamNames: boolean;
  aisExportTypes: Array<AisExportType>;
  addEmptyLabels: number;
  csvImportEnabled: boolean;
  overrideRequestEnabled: boolean;
  disabled: boolean;
  assignedLabs: Array<{
    __typename?: 'AssignedLab';
    customerId: string;
    supplier: TestTubeSupplier;
    runningNumber: number;
    lab: { __typename?: 'Lab'; id: string; name: string };
  }>;
  catalogs: Array<{
    __typename?: 'Catalog';
    id: string;
    name: string;
    lab: { __typename?: 'Lab'; id: string; shortName: string };
  }>;
  insurances: Array<{ __typename?: 'Insurance'; id: string }>;
  specialRates: Array<{ __typename?: 'SpecialRate'; id: string }>;
  lgInsurances: Array<{ __typename?: 'Insurance'; id: string }>;
  theme?: { __typename?: 'Theme'; id: string; name: string } | null;
} & { ' $fragmentName'?: 'UpdateDoctorModal_DoctorFragment' };

export type CreateInsuranceMutationVariables = Exact<{
  input: InsuranceInput;
}>;

export type CreateInsuranceMutation = {
  __typename?: 'Mutation';
  createInsurance?: { __typename?: 'Insurance'; id: string } | null;
};

export type UpdateInsuranceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: InsuranceInput;
}>;

export type UpdateInsuranceMutation = {
  __typename?: 'Mutation';
  updateInsurance?: { __typename?: 'Insurance'; id: string } | null;
};

export type BasedataLabQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BasedataLabQuery = {
  __typename?: 'Query';
  lab?: {
    __typename?: 'Lab';
    id: string;
    name: string;
    shortName: string;
    longName: string;
    meAddress: string;
    city: string;
    email: string;
    fax: string;
    phone: string;
    street: string;
    zip: string;
    zplLabelTemplate: string;
    zplLabelWidth: number;
    zplLabelHeight: number;
    newReorderRunningNumber: boolean;
    diagnoseRequired: boolean;
    pickupTransferDocument: boolean;
    printPartnerLab1Params: boolean;
    printPartnerLab2Params: boolean;
    printPartnerLab3Params: boolean;
    runningNumber: number;
    invoiceCounter: number;
  } | null;
};

export type UpdateLabMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: LabInput;
}>;

export type UpdateLabMutation = { __typename?: 'Mutation'; updateLab?: { __typename?: 'Lab'; id: string } | null };

export type CatalogsQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type CatalogsQuery = {
  __typename?: 'Query';
  labCatalogs?: Array<{ __typename?: 'Catalog'; id: string; name: string; description: string }> | null;
  labParameters?: Array<
    { __typename?: 'Parameter' } & {
      ' $fragmentRefs'?: { CatalogForm_ParameterFragment: CatalogForm_ParameterFragment };
    }
  > | null;
};

export type CloneCatalogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CloneCatalogMutation = {
  __typename?: 'Mutation';
  cloneCatalog?: { __typename?: 'Catalog'; id: string } | null;
};

export type DeleteCatalogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCatalogMutation = { __typename?: 'Mutation'; deleteCatalog?: boolean | null };

export type DiagnosesQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type DiagnosesQuery = {
  __typename?: 'Query';
  labDiagnoses?: Array<{ __typename?: 'Diagnose'; id: string; name: string; text: string }> | null;
};

export type DeleteLabTransferDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLabTransferDocumentMutation = {
  __typename?: 'Mutation';
  deleteLabTransferDocument?: { __typename?: 'Lab'; id: string } | null;
};

export type DeleteLabAisExportDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteLabAisExportDocumentMutation = {
  __typename?: 'Mutation';
  deleteLabAisExportDocument?: { __typename?: 'Lab'; id: string } | null;
};

export type DocumentsLabQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DocumentsLabQuery = {
  __typename?: 'Query';
  lab?: { __typename?: 'Lab'; id: string; hasTransferDocument: boolean; hasAisExportDocument: boolean } | null;
};

export type FormsQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type FormsQuery = {
  __typename?: 'Query';
  labForms?: Array<{
    __typename?: 'Form';
    id: string;
    name: string;
    header: string;
    description: string;
    pickup: boolean;
    printBeforeSend: boolean;
    printRequired: boolean;
    printCopies: number;
    hasDocument: boolean;
    direct: boolean;
    restore: boolean;
    customizer: FormCustomizer;
    fields: Array<{
      __typename?: 'FormField';
      id: string;
      name: string;
      label: string;
      placeholder: string;
      required: boolean;
      help: string;
      defaultValue: string;
      regex: string;
      type: FormFieldType;
      options: Array<{ __typename?: 'FormFieldOption'; label: string; value: string; selected: boolean }>;
    }>;
  }> | null;
};

export type ParametersQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type ParametersQuery = {
  __typename?: 'Query';
  labParameters?: Array<{
    __typename?: 'Parameter';
    id: string;
    longName: string;
    shortName: string;
    deactivated: boolean;
    doctorBilling: boolean;
    patientBilling: boolean;
    description: string;
    billingDescription: string;
    edifactNumber?: number | null;
    link: string;
    lgDoctorBilling: boolean;
    storagePeriod: number;
    evalHours: number;
    priceDoctor?: number | null;
    priceLgDoctor?: number | null;
    pricePatient?: number | null;
    partnerLab: PartnerLab;
    synonyms: Array<string>;
    acute: boolean;
    volume: number;
    specimens: Array<{ __typename?: 'Specimen'; id: string; name: string; classification: string }>;
    withParameters: Array<{ __typename?: 'Parameter'; id: string; shortName: string }>;
    withoutParameters: Array<{ __typename?: 'Parameter'; id: string; shortName: string }>;
    specialRates: Array<{ __typename?: 'SpecialRate'; id: string; shortName: string }>;
    forms: Array<{ __typename?: 'Form'; id: string; name: string }>;
  }> | null;
};

export type PeriodicGroupsQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type PeriodicGroupsQuery = {
  __typename?: 'Query';
  labPeriodicGroups?: Array<{
    __typename?: 'PeriodicGroup';
    id: string;
    name: string;
    maxCount: number;
    period: Period;
  }> | null;
};

export type SpecialRatesQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type SpecialRatesQuery = {
  __typename?: 'Query';
  labSpecialRates?: Array<{
    __typename?: 'SpecialRate';
    id: string;
    name: string;
    shortName: string;
    code: string;
  }> | null;
};

export type SpecimensQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type SpecimensQuery = {
  __typename?: 'Query';
  labSpecimens?: Array<{
    __typename?: 'Specimen';
    id: string;
    name: string;
    storagePeriod: number;
    classification: string;
    features: Array<SpecimenFeature>;
    group: SpecimenGroup;
    requiresLocalizations: boolean;
    localizationInputEnabled: boolean;
    predefinedLocalizations: Array<string>;
    testTubes: Array<{
      __typename?: 'TestTube';
      id: string;
      name: string;
      supplier: TestTubeSupplier;
      color: TestTubeColor;
    }>;
    forms: Array<{ __typename?: 'Form'; id: string; name: string }>;
  }> | null;
  labTestTubes?: Array<{
    __typename?: 'TestTube';
    id: string;
    name: string;
    supplier: TestTubeSupplier;
    color: TestTubeColor;
  }> | null;
  labForms?: Array<{ __typename?: 'Form'; id: string; name: string }> | null;
};

export type TestTubesQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type TestTubesQuery = {
  __typename?: 'Query';
  labTestTubes?: Array<{
    __typename?: 'TestTube';
    id: string;
    name: string;
    color: TestTubeColor;
    supplier: TestTubeSupplier;
    labelText: string;
    labelText2: string;
    link: string;
    hasImage: boolean;
    volume: number;
  }> | null;
};

export type CatalogForm_ParameterFragment = {
  __typename?: 'Parameter';
  id: string;
  shortName: string;
  longName: string;
  synonyms: Array<string>;
} & { ' $fragmentName'?: 'CatalogForm_ParameterFragment' };

export type CatalogParameterFormQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type CatalogParameterFormQuery = {
  __typename?: 'Query';
  insurances?: Array<{ __typename?: 'Insurance'; id: string; shortName: string }> | null;
  labSpecialRates?: Array<{
    __typename?: 'SpecialRate';
    id: string;
    name: string;
    shortName: string;
    code: string;
  }> | null;
  labDiagnoses?: Array<{ __typename?: 'Diagnose'; id: string; name: string }> | null;
  labSpecimens?: Array<{ __typename?: 'Specimen'; id: string; name: string; classification: string }> | null;
  labForms?: Array<{ __typename?: 'Form'; id: string; name: string }> | null;
  labPeriodicGroups?: Array<{ __typename?: 'PeriodicGroup'; id: string; name: string }> | null;
};

export type CatalogParametersQueryVariables = Exact<{
  catalogId: Scalars['ID']['input'];
}>;

export type CatalogParametersQuery = {
  __typename?: 'Query';
  catalog?: {
    __typename?: 'Catalog';
    id: string;
    name: string;
    catalogParameters: Array<{
      __typename?: 'CatalogParameter';
      id: string;
      useSpecimens: boolean;
      useSpecialRates: boolean;
      useForms: boolean;
      useDoctorBilling: boolean;
      doctorBilling: boolean;
      usePatientBilling: boolean;
      patientBilling: boolean;
      useLgDoctorBilling: boolean;
      lgDoctorBilling: boolean;
      useInsuranceDefinitions: boolean;
      useLgInsuranceDefinitions: boolean;
      usePriceDoctor: boolean;
      priceDoctor?: number | null;
      usePricePatient: boolean;
      pricePatient?: number | null;
      usePriceLgDoctor: boolean;
      priceLgDoctor?: number | null;
      specimens: Array<{ __typename?: 'Specimen'; id: string; name: string; classification: string }>;
      specialRates: Array<{ __typename?: 'SpecialRate'; id: string; shortName: string }>;
      forms: Array<{ __typename?: 'Form'; id: string; name: string }>;
      parameter: {
        __typename?: 'Parameter';
        id: string;
        shortName: string;
        longName: string;
        edifactNumber?: number | null;
        synonyms: Array<string>;
        description: string;
        link: string;
        deactivated: boolean;
        doctorBilling: boolean;
        patientBilling: boolean;
        lgDoctorBilling: boolean;
        priceDoctor?: number | null;
        pricePatient?: number | null;
        priceLgDoctor?: number | null;
        withoutParameters: Array<{ __typename?: 'Parameter'; id: string; shortName: string }>;
        withParameters: Array<{ __typename?: 'Parameter'; id: string; shortName: string }>;
        specimens: Array<{ __typename?: 'Specimen'; id: string; name: string; classification: string }>;
        specialRates: Array<{ __typename?: 'SpecialRate'; id: string; shortName: string }>;
        forms: Array<{ __typename?: 'Form'; id: string; name: string }>;
      };
    }>;
  } | null;
};

export type CopyCatalogParametersMutMutationVariables = Exact<{
  input: CopyCatalogParametersInput;
}>;

export type CopyCatalogParametersMutMutation = { __typename?: 'Mutation'; copyCatalogParameters?: string | null };

export type CopyCatalogParametersQueryVariables = Exact<{
  catalogId: Scalars['ID']['input'];
  labId: Scalars['ID']['input'];
}>;

export type CopyCatalogParametersQuery = {
  __typename?: 'Query';
  catalog?: {
    __typename?: 'Catalog';
    id: string;
    name: string;
    catalogParameters: Array<{
      __typename?: 'CatalogParameter';
      id: string;
      parameter: { __typename?: 'Parameter'; id: string; shortName: string; longName: string };
    }>;
  } | null;
  labCatalogs?: Array<{ __typename?: 'Catalog'; id: string; name: string }> | null;
};

export type CreateCatalogMutationVariables = Exact<{
  input: CatalogCreateInput;
}>;

export type CreateCatalogMutation = {
  __typename?: 'Mutation';
  createCatalog?: { __typename?: 'Catalog'; id: string } | null;
};

export type CatalogQueryVariables = Exact<{
  catalogId: Scalars['ID']['input'];
}>;

export type CatalogQuery = {
  __typename?: 'Query';
  catalog?: {
    __typename?: 'Catalog';
    id: string;
    name: string;
    description: string;
    catalogParameters: Array<{
      __typename?: 'CatalogParameter';
      id: string;
      parameter: { __typename?: 'Parameter'; id: string };
    }>;
  } | null;
};

export type UpdateCatalogMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CatalogUpdateInput;
}>;

export type UpdateCatalogMutation = {
  __typename?: 'Mutation';
  updateCatalog?: { __typename?: 'Catalog'; id: string } | null;
};

export type UpdateCatalogParametersMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  input: CatalogParameterUpdateInput;
}>;

export type UpdateCatalogParametersMutation = {
  __typename?: 'Mutation';
  updateCatalogParameters?: Array<{ __typename?: 'CatalogParameter'; id: string }> | null;
};

export type MultiCatalogParametersQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type MultiCatalogParametersQuery = {
  __typename?: 'Query';
  individualCatalogParameters?: Array<{
    __typename?: 'CatalogParameter';
    id: string;
    useForms: boolean;
    useLgDoctorBilling: boolean;
    lgDoctorBilling: boolean;
    useLgInsuranceDefinitions: boolean;
    useSpecimens: boolean;
    doctorBilling: boolean;
    patientBilling: boolean;
    useDoctorBilling: boolean;
    useInsuranceDefinitions: boolean;
    usePatientBilling: boolean;
    useSpecialRates: boolean;
    priceDoctor?: number | null;
    priceLgDoctor?: number | null;
    pricePatient?: number | null;
    usePriceDoctor: boolean;
    usePriceLgDoctor: boolean;
    usePricePatient: boolean;
    insuranceDefinitions: Array<{
      __typename?: 'InsuranceDefinition';
      id: string;
      lg: boolean;
      insurance: { __typename?: 'Insurance'; id: string };
      diagnoses: Array<{ __typename?: 'Diagnose'; id: string }>;
      periodicGroup?: { __typename?: 'PeriodicGroup'; id: string } | null;
    }>;
    specialRates: Array<{ __typename?: 'SpecialRate'; id: string }>;
    specimens: Array<{ __typename?: 'Specimen'; id: string }>;
    forms: Array<{ __typename?: 'Form'; id: string }>;
    parameter: {
      __typename?: 'Parameter';
      id: string;
      shortName: string;
      deactivated: boolean;
      doctorBilling: boolean;
      patientBilling: boolean;
      link: string;
      edifactNumber?: number | null;
      description: string;
      lgDoctorBilling: boolean;
      pricePatient?: number | null;
      priceDoctor?: number | null;
      priceLgDoctor?: number | null;
      synonyms: Array<string>;
      specimens: Array<{ __typename?: 'Specimen'; id: string }>;
      withParameters: Array<{ __typename?: 'Parameter'; id: string }>;
      withoutParameters: Array<{ __typename?: 'Parameter'; id: string }>;
      insuranceDefinitions: Array<{
        __typename?: 'InsuranceDefinition';
        id: string;
        lg: boolean;
        insurance: { __typename?: 'Insurance'; id: string };
        diagnoses: Array<{ __typename?: 'Diagnose'; id: string }>;
        periodicGroup?: { __typename?: 'PeriodicGroup'; id: string } | null;
      }>;
      specialRates: Array<{ __typename?: 'SpecialRate'; id: string }>;
      forms: Array<{ __typename?: 'Form'; id: string }>;
    };
  }> | null;
};

export type CreateDiagnoseMutationVariables = Exact<{
  input: DiagnoseCreateInput;
}>;

export type CreateDiagnoseMutation = {
  __typename?: 'Mutation';
  createDiagnose?: { __typename?: 'Diagnose'; id: string } | null;
};

export type UpdateDiagnoseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DiagnoseUpdateInput;
}>;

export type UpdateDiagnoseMutation = {
  __typename?: 'Mutation';
  updateDiagnose?: { __typename?: 'Diagnose'; id: string } | null;
};

export type CreateFormMutationVariables = Exact<{
  input: FormInput;
}>;

export type CreateFormMutation = { __typename?: 'Mutation'; createForm?: { __typename?: 'Form'; id: string } | null };

export type DeleteFormDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteFormDocumentMutation = {
  __typename?: 'Mutation';
  deleteFormDocument?: { __typename?: 'Form'; id: string } | null;
};

export type UpdateFormMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: FormInput;
}>;

export type UpdateFormMutation = { __typename?: 'Mutation'; updateForm?: { __typename?: 'Form'; id: string } | null };

export type CreateParameterMutationVariables = Exact<{
  input: ParameterCreateInput;
}>;

export type CreateParameterMutation = {
  __typename?: 'Mutation';
  createParameter?: { __typename?: 'Parameter'; id: string } | null;
};

export type ParameterFormQueryVariables = Exact<{
  labId: Scalars['ID']['input'];
}>;

export type ParameterFormQuery = {
  __typename?: 'Query';
  insurances?: Array<{ __typename?: 'Insurance'; id: string; shortName: string }> | null;
  labSpecialRates?: Array<{
    __typename?: 'SpecialRate';
    id: string;
    name: string;
    shortName: string;
    code: string;
  }> | null;
  labDiagnoses?: Array<{ __typename?: 'Diagnose'; id: string; name: string }> | null;
  labSpecimens?: Array<{ __typename?: 'Specimen'; id: string; name: string; classification: string }> | null;
  labForms?: Array<{ __typename?: 'Form'; id: string; name: string }> | null;
  labPeriodicGroups?: Array<{ __typename?: 'PeriodicGroup'; id: string; name: string }> | null;
};

export type UpdateParameterMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ParameterUpdateInput;
}>;

export type UpdateParameterMutation = {
  __typename?: 'Mutation';
  updateParameter?: { __typename?: 'Parameter'; id: string } | null;
};

export type ParameterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ParameterQuery = {
  __typename?: 'Query';
  labParameter?: {
    __typename?: 'Parameter';
    id: string;
    longName: string;
    shortName: string;
    deactivated: boolean;
    doctorBilling: boolean;
    patientBilling: boolean;
    description: string;
    billingDescription: string;
    edifactNumber?: number | null;
    link: string;
    lgDoctorBilling: boolean;
    storagePeriod: number;
    evalHours: number;
    priceDoctor?: number | null;
    priceLgDoctor?: number | null;
    pricePatient?: number | null;
    partnerLab: PartnerLab;
    synonyms: Array<string>;
    acute: boolean;
    volume: number;
    specimens: Array<{ __typename?: 'Specimen'; id: string }>;
    withParameters: Array<{ __typename?: 'Parameter'; id: string }>;
    withoutParameters: Array<{ __typename?: 'Parameter'; id: string }>;
    insuranceDefinitions: Array<{
      __typename?: 'InsuranceDefinition';
      id: string;
      lg: boolean;
      insurance: { __typename?: 'Insurance'; id: string };
      diagnoses: Array<{ __typename?: 'Diagnose'; id: string }>;
      periodicGroup?: { __typename?: 'PeriodicGroup'; id: string } | null;
    }>;
    specialRates: Array<{ __typename?: 'SpecialRate'; id: string }>;
    forms: Array<{ __typename?: 'Form'; id: string }>;
  } | null;
};

export type CreatePeriodicGroupMutationVariables = Exact<{
  input: PeriodicGroupCreateInput;
}>;

export type CreatePeriodicGroupMutation = {
  __typename?: 'Mutation';
  createPeriodicGroup?: { __typename?: 'PeriodicGroup'; id: string } | null;
};

export type UpdatePeriodicGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: PeriodicGroupUpdateInput;
}>;

export type UpdatePeriodicGroupMutation = {
  __typename?: 'Mutation';
  updatePeriodicGroup?: { __typename?: 'PeriodicGroup'; id: string } | null;
};

export type CreateSpecialRateMutationVariables = Exact<{
  input: SpecialRateCreateInput;
}>;

export type CreateSpecialRateMutation = {
  __typename?: 'Mutation';
  createSpecialRate?: { __typename?: 'SpecialRate'; id: string } | null;
};

export type UpdateSpecialRateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SpecialRateUpdateInput;
}>;

export type UpdateSpecialRateMutation = {
  __typename?: 'Mutation';
  updateSpecialRate?: { __typename?: 'SpecialRate'; id: string } | null;
};

export type CreateSpecimenMutationVariables = Exact<{
  input: SpecimenCreateInput;
}>;

export type CreateSpecimenMutation = {
  __typename?: 'Mutation';
  createSpecimen?: { __typename?: 'Specimen'; id: string } | null;
};

export type UpdateSpecimenMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SpecimenUpdateInput;
}>;

export type UpdateSpecimenMutation = {
  __typename?: 'Mutation';
  updateSpecimen?: { __typename?: 'Specimen'; id: string } | null;
};

export type CreateTestTubeMutationVariables = Exact<{
  input: TestTubeCreateInput;
}>;

export type CreateTestTubeMutation = {
  __typename?: 'Mutation';
  createTestTube?: { __typename?: 'TestTube'; id: string } | null;
};

export type DeleteTestTubeImageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTestTubeImageMutation = {
  __typename?: 'Mutation';
  deleteTestTubeImage?: { __typename?: 'TestTube'; id: string } | null;
};

export type UpdateTestTubeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: TestTubeUpdateInput;
}>;

export type UpdateTestTubeMutation = {
  __typename?: 'Mutation';
  updateTestTube?: { __typename?: 'TestTube'; id: string } | null;
};

export type CreateThemeMutationVariables = Exact<{
  input: ThemeInput;
}>;

export type CreateThemeMutation = {
  __typename?: 'Mutation';
  createTheme?: { __typename?: 'Theme'; id: string } | null;
};

export type UpdateThemeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ThemeInput;
}>;

export type UpdateThemeMutation = {
  __typename?: 'Mutation';
  updateTheme?: { __typename?: 'Theme'; id: string } | null;
};

export type SaveUserAssignmentMutationVariables = Exact<{
  input: UserAssignmentInput;
}>;

export type SaveUserAssignmentMutation = {
  __typename?: 'Mutation';
  saveUserAssignment?: { __typename?: 'UserAssignment'; id: string } | null;
};

export type DailyReportQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DailyReportQuery = {
  __typename?: 'Query';
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    assignedLabs: Array<{
      __typename?: 'AssignedLab';
      lab: { __typename?: 'Lab'; id: string; name: string; identifier: string };
    }>;
  } | null;
};

export type FormRenderer_FormFragment = {
  __typename?: 'Form';
  id: string;
  name: string;
  description: string;
  header: string;
  fields: Array<{
    __typename?: 'FormField';
    id: string;
    defaultValue: string;
    regex: string;
    help: string;
    label: string;
    required: boolean;
    type: FormFieldType;
    placeholder: string;
    name: string;
    options: Array<{ __typename?: 'FormFieldOption'; label: string; selected: boolean; value: string }>;
  }>;
} & { ' $fragmentName'?: 'FormRenderer_FormFragment' };

export type DirectFormsQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DirectFormsQuery = {
  __typename?: 'Query';
  directForms?: Array<
    { __typename?: 'Form' } & { ' $fragmentRefs'?: { FormRenderer_FormFragment: FormRenderer_FormFragment } }
  > | null;
};

export type PatientInfoQueryVariables = Exact<{ [key: string]: never }>;

export type PatientInfoQuery = {
  __typename?: 'Query';
  insurances?: Array<{ __typename?: 'Insurance'; id: string; code: string; shortName: string }> | null;
};

export type OpenRequestsBySvnrModal_RequestFragment = {
  __typename?: 'Request';
  id: string;
  createdAt: string;
  orders: Array<{
    __typename?: 'Order';
    id: string;
    orderParameters: Array<{ __typename?: 'OrderParameter'; id: string; shortName: string; longName: string }>;
  }>;
} & { ' $fragmentName'?: 'OpenRequestsBySvnrModal_RequestFragment' };

export type ParameterSelectionQueryVariables = Exact<{
  patientData: PatientDataInput;
  doctorId: Scalars['ID']['input'];
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ParameterSelectionQuery = {
  __typename?: 'Query';
  requestableParameters?: Array<
    { __typename?: 'RequestableParameter' } & {
      ' $fragmentRefs'?: {
        ParameterSelection_RequestableParameterFragment: ParameterSelection_RequestableParameterFragment;
      };
    }
  > | null;
  insurances?: Array<{ __typename?: 'Insurance'; id: string; code: string; shortName: string }> | null;
  profiles?: Array<
    { __typename?: 'Profile' } & {
      ' $fragmentRefs'?: { ParameterSelection_ProfileFragment: ParameterSelection_ProfileFragment };
    }
  > | null;
  doctorSheets?: Array<
    { __typename?: 'Sheet' } & {
      ' $fragmentRefs'?: { ParameterSelection_DoctorSheetFragment: ParameterSelection_DoctorSheetFragment };
    }
  > | null;
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    showPriceSuggestion: boolean;
    patientBilling: boolean;
    flipParamNames: boolean;
    showPrices: boolean;
    overrideRequestEnabled: boolean;
  } | null;
};

export type ParameterSelection_RequestableParameterFragment = {
  __typename?: 'RequestableParameter';
  id: string;
  mainParameterId: string;
  shortName: string;
  longName: string;
  withoutParameterIds: Array<string>;
  withoutParameterNames: Array<string>;
  withParameterNames: Array<string>;
  pricePatient?: number | null;
  link: string;
  description: string;
  billingDescription: string;
  evalHours: number;
  synonyms: Array<string>;
  acute: boolean;
  volume: number;
  billingInfos: Array<{
    __typename?: 'BillingInfo';
    billingType: BillingType;
    price?: number | null;
    text: string;
    additionalText: string;
    specialRateId?: string | null;
    diagnoseId?: string | null;
  }>;
  specimens: Array<{
    __typename?: 'RequestableSpecimen';
    id: string;
    classification: string;
    name: string;
    storagePeriod: number;
    requiresLocalizations: boolean;
    localizationInputEnabled: boolean;
    predefinedLocalizations: Array<string>;
    testTubes: Array<{
      __typename?: 'TestTube';
      id: string;
      color: TestTubeColor;
      name: string;
      hasImage: boolean;
      labelText: string;
      labelText2: string;
      link: string;
      volume: number;
    }>;
  }>;
  lab: { __typename?: 'Lab'; id: string; name: string; shortName: string; diagnoseRequired: boolean };
} & { ' $fragmentName'?: 'ParameterSelection_RequestableParameterFragment' };

export type ParameterSelection_ProfileFragment = {
  __typename?: 'Profile';
  id: string;
  name: string;
  hotKey?: HotKey | null;
  diagnose: string;
  parameters: Array<{
    __typename?: 'ProfileParameter';
    id: string;
    preferredBillingType?: BillingType | null;
    parameter: {
      __typename?: 'Parameter';
      id: string;
      shortName: string;
      longName: string;
      acute: boolean;
      lab: { __typename?: 'Lab'; id: string; shortName: string };
    };
  }>;
  diagnoses: Array<{ __typename?: 'Diagnose'; id: string; name: string }>;
} & { ' $fragmentName'?: 'ParameterSelection_ProfileFragment' };

export type ParameterSelection_DoctorSheetFragment = {
  __typename?: 'Sheet';
  id: string;
  doctorId?: string | null;
  labId?: string | null;
  data: any;
  lab?: { __typename?: 'Lab'; id: string; name: string } | null;
} & { ' $fragmentName'?: 'ParameterSelection_DoctorSheetFragment' };

export type OpenRequestsBySvnrQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
  svnr: Scalars['String']['input'];
}>;

export type OpenRequestsBySvnrQuery = {
  __typename?: 'Query';
  openRequests?: Array<
    { __typename?: 'Request' } & {
      ' $fragmentRefs'?: { OpenRequestsBySvnrModal_RequestFragment: OpenRequestsBySvnrModal_RequestFragment };
    }
  > | null;
};

export type QueryParameterSelectionPatientRequestsQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
  svnr: Scalars['String']['input'];
}>;

export type QueryParameterSelectionPatientRequestsQuery = {
  __typename?: 'Query';
  patientRequests?: Array<{ __typename?: 'Request'; id: string; reorderable: boolean }> | null;
};

export type AddParametersMutationVariables = Exact<{
  input: AddParametersInput;
}>;

export type AddParametersMutation = {
  __typename?: 'Mutation';
  addParameters?: {
    __typename?: 'AddParametersResponse';
    incompatibleParams: Array<string>;
    paramsToAdd: Array<{
      __typename?: 'ParameterToAdd';
      parameterId: string;
      billingInfo?: {
        __typename?: 'BillingInfo';
        billingType: BillingType;
        price?: number | null;
        text: string;
        additionalText: string;
        specialRateId?: string | null;
        diagnoseId?: string | null;
      } | null;
    }>;
  } | null;
};

export type PatientRegistrationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PatientRegistrationQuery = {
  __typename?: 'Query';
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    flipParamNames: boolean;
    insurances: Array<{ __typename?: 'Insurance'; id: string; shortName: string; name: string; code: string }>;
  } | null;
  insurances?: Array<{ __typename?: 'Insurance'; id: string; code: string; shortName: string; name: string }> | null;
};

export type RequestQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
}>;

export type RequestQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    createdAt: string;
    modifiedAt?: string | null;
    sentAt?: string | null;
    sampledAt?: string | null;
    status: RequestStatus;
    insuranceShortName: string;
    insuranceName: string;
    importType?: ImportType | null;
    importRequestId?: string | null;
    additionalData: any;
    createdByLastName: string;
    createdByFirstName: string;
    modifiedByLastName: string;
    modifiedByFirstName: string;
    sentByFirstName: string;
    sentByLastName: string;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      number: string;
      diagnose: string;
      labInfo: string;
      acute: boolean;
      type: OrderType;
      createdAt: string;
      createdByFirstName: string;
      createdByLastName: string;
      receivedAt?: string | null;
      invoiceNumber?: string | null;
      invoiceCancellationNumber?: string | null;
      orderLabels: Array<{
        __typename?: 'OrderLabel';
        id: string;
        text: string;
        text2: string;
        number: string;
        name: string;
        classification: string;
        color: TestTubeColor;
        features: Array<SpecimenFeature>;
        printedAt?: string | null;
        location: string;
        paramShortNames: Array<string>;
        specimenId: string;
        testTubeId: string;
      }>;
      orderParameters: Array<{
        __typename?: 'OrderParameter';
        id: string;
        shortName: string;
        longName: string;
        diagnose: string;
        billingType: BillingType;
        specialRateCode: string;
        specialRateShortName: string;
        price?: number | null;
        acute: boolean;
      }>;
      orderFiles: Array<{
        __typename?: 'OrderFile';
        id: string;
        printedAt?: string | null;
        name: string;
        type: OrderFileType;
        fileName: string;
        size: number;
        printRequired: boolean;
        printable: boolean;
        pickup: boolean;
      }>;
      orderForms: Array<{
        __typename?: 'OrderForm';
        id: string;
        header: string;
        fields: Array<{
          __typename?: 'OrderFormField';
          id: string;
          values: Array<string>;
          label: string;
          type: FormFieldType;
        }>;
      }>;
      lab: { __typename?: 'Lab'; id: string; name: string };
    }>;
    patientData: {
      __typename?: 'PatientData';
      externalId: string;
      title: string;
      firstName: string;
      lastName: string;
      birthday: string;
      gender: Gender;
      svnr: string;
      insuranceCategory: InsuranceCategory;
      street: string;
      zip: string;
      city: string;
      country: string;
      phone: string;
      email: string;
      idCardType: IdCardType;
      idCardNumber: string;
      idCardAuthority: string;
      insuredPerson?: {
        __typename?: 'InsuredPerson';
        title: string;
        firstName: string;
        lastName: string;
        birthday: string;
        gender?: Gender | null;
        svnr: string;
        street: string;
        zip: string;
        city: string;
        country: string;
        phone: string;
        email: string;
      } | null;
    };
  } | null;
  doctor?: { __typename?: 'Doctor'; id: string; flipParamNames: boolean; showPrices: boolean } | null;
};

export type MarkOrderLabelPrintedMutationVariables = Exact<{
  orderLabelId: Scalars['ID']['input'];
}>;

export type MarkOrderLabelPrintedMutation = {
  __typename?: 'Mutation';
  markOrderLabelPrinted?: { __typename?: 'OrderLabel'; id: string; printedAt?: string | null } | null;
};

export type MarkOrderFilePrintedMutationVariables = Exact<{
  orderFileId: Scalars['ID']['input'];
}>;

export type MarkOrderFilePrintedMutation = {
  __typename?: 'Mutation';
  markOrderFilePrinted?: { __typename?: 'OrderFile'; id: string; printedAt?: string | null } | null;
};

export type CancelOrderInvoiceMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type CancelOrderInvoiceMutation = {
  __typename?: 'Mutation';
  cancelOrderInvoice?: { __typename?: 'Order'; id: string } | null;
};

export type RequestSummaryQueryVariables = Exact<{
  catalogParamIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  requestIdForReorder?: InputMaybe<Scalars['ID']['input']>;
  doctorId: Scalars['ID']['input'];
}>;

export type RequestSummaryQuery = {
  __typename?: 'Query';
  insurances?: Array<{ __typename?: 'Insurance'; id: string; code: string; shortName: string }> | null;
  requiredForms?: Array<{
    __typename?: 'Form';
    id: string;
    name: string;
    header: string;
    description: string;
    fields: Array<{
      __typename?: 'FormField';
      id: string;
      name: string;
      type: FormFieldType;
      required: boolean;
      placeholder: string;
      label: string;
      help: string;
      defaultValue: string;
      regex: string;
      options: Array<{ __typename?: 'FormFieldOption'; label: string; selected: boolean; value: string }>;
    }>;
  }> | null;
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    showPriceSuggestion: boolean;
    showPrices: boolean;
    patientBilling: boolean;
    flipParamNames: boolean;
    addEmptyLabels: number;
  } | null;
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: 'Mutation';
  createRequest?:
    | ({ __typename?: 'Request' } & {
        ' $fragmentRefs'?: { RequestSummary_SavedRequestFragment: RequestSummary_SavedRequestFragment };
      })
    | null;
};

export type UpdateRequestMutationVariables = Exact<{
  input: UpdateRequestInput;
}>;

export type UpdateRequestMutation = {
  __typename?: 'Mutation';
  updateRequest?:
    | ({ __typename?: 'Request' } & {
        ' $fragmentRefs'?: { RequestSummary_SavedRequestFragment: RequestSummary_SavedRequestFragment };
      })
    | null;
};

export type ReorderMutationVariables = Exact<{
  input: ReorderInput;
}>;

export type ReorderMutation = {
  __typename?: 'Mutation';
  reorder?:
    | ({ __typename?: 'Request' } & {
        ' $fragmentRefs'?: { RequestSummary_SavedRequestFragment: RequestSummary_SavedRequestFragment };
      })
    | null;
};

export type RequestSummary_SavedRequestFragment = {
  __typename?: 'Request';
  id: string;
  additionalData: any;
  orders: Array<{
    __typename?: 'Order';
    aisExportedAt?: string | null;
    orderFiles: Array<{
      __typename?: 'OrderFile';
      id: string;
      printedAt?: string | null;
      printable: boolean;
      printRequired: boolean;
      printBeforeSend: boolean;
      printCopies: number;
    }>;
    orderLabels: Array<{
      __typename?: 'OrderLabel';
      id: string;
      printedAt?: string | null;
      specimenId: string;
      testTubeId: string;
      location: string;
    }>;
  }>;
} & { ' $fragmentName'?: 'RequestSummary_SavedRequestFragment' };

export type LocalizationsChooserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LocalizationsChooserQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      orderLabels: Array<{ __typename?: 'OrderLabel'; id: string; specimenId: string; location: string }>;
    }>;
  } | null;
};

export type CreateProfileMutationVariables = Exact<{
  input: ProfileInput;
}>;

export type CreateProfileMutation = {
  __typename?: 'Mutation';
  createProfile?: { __typename?: 'Profile'; id: string } | null;
};

export type PatientRequestsQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
  svnr: Scalars['String']['input'];
}>;

export type PatientRequestsQuery = {
  __typename?: 'Query';
  patientRequests?: Array<{
    __typename?: 'Request';
    id: string;
    createdAt: string;
    reorderable: boolean;
    reorderableUntil?: string | null;
    createdByFrontDesk: boolean;
    status: RequestStatus;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      lab: { __typename?: 'Lab'; id: string };
      orderParameters: Array<{
        __typename?: 'OrderParameter';
        id: string;
        shortName: string;
        longName: string;
        billingType: BillingType;
      }>;
    }>;
    patientData: {
      __typename?: 'PatientData';
      birthday: string;
      city: string;
      country: string;
      email: string;
      externalId: string;
      firstName: string;
      lastName: string;
      gender: Gender;
      zip: string;
      svnr: string;
      title: string;
      street: string;
      phone: string;
      insuranceCode: string;
      idCardAuthority: string;
      idCardNumber: string;
      idCardType: IdCardType;
      insuranceCategory: InsuranceCategory;
      insuredPerson?: {
        __typename?: 'InsuredPerson';
        phone: string;
        street: string;
        title: string;
        svnr: string;
        zip: string;
        birthday: string;
        gender?: Gender | null;
        firstName: string;
        email: string;
        country: string;
        city: string;
        lastName: string;
      } | null;
    };
  }> | null;
};

export type AvailableBillingInfosQueryVariables = Exact<{
  catalogParamId: Scalars['ID']['input'];
  selectedParameters: Array<SelectedParameterInput> | SelectedParameterInput;
  doctorId: Scalars['ID']['input'];
  requestIdForReorder: Scalars['ID']['input'];
  patientData: PatientDataInput;
}>;

export type AvailableBillingInfosQuery = {
  __typename?: 'Query';
  availableBillingInfos?: {
    __typename?: 'AvailableBillingInfos';
    billingInfos: Array<{
      __typename?: 'BillingInfo';
      additionalText: string;
      billingType: BillingType;
      diagnoseId?: string | null;
      price?: number | null;
      specialRateId?: string | null;
      text: string;
    }>;
    exceededPeriodicGroups: Array<{
      __typename?: 'ExceededPeriodicGroup';
      lg: boolean;
      paramsInGroup: Array<string>;
      periodicGroup: { __typename?: 'PeriodicGroup'; id: string; name: string; period: Period; maxCount: number };
    }>;
  } | null;
};

export type RequestForReorderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RequestForReorderQuery = {
  __typename?: 'Query';
  request?: {
    __typename?: 'Request';
    id: string;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      orderParameters: Array<{
        __typename?: 'OrderParameter';
        id: string;
        shortName: string;
        longName: string;
        billingType: BillingType;
      }>;
      lab: { __typename?: 'Lab'; id: string; name: string };
    }>;
  } | null;
};

export type DoctorSettingsQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DoctorSettingsQuery = {
  __typename?: 'Query';
  doctor?: {
    __typename?: 'Doctor';
    id: string;
    salutation: DoctorSalutation;
    preTitle: string;
    postTitle: string;
    firstName: string;
    lastName: string;
    name: string;
    specialist: string;
    street: string;
    zip: string;
    city: string;
    email: string;
    phone: string;
    billingPreference: BillingPreference;
    autoDiagnose: boolean;
    addEmptyLabels: number;
    printTransferDocument: boolean;
  } | null;
};

export type UpdateDoctorSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: DoctorSettingsInput;
}>;

export type UpdateDoctorSettingsMutation = {
  __typename?: 'Mutation';
  updateDoctorSettings?: { __typename?: 'Doctor'; id: string } | null;
};

export type ProfilesQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type ProfilesQuery = {
  __typename?: 'Query';
  profiles?: Array<{
    __typename?: 'Profile';
    id: string;
    name: string;
    hotKey?: HotKey | null;
    diagnose: string;
    parameters: Array<{
      __typename?: 'ProfileParameter';
      id: string;
      preferredBillingType?: BillingType | null;
      parameter: {
        __typename?: 'Parameter';
        id: string;
        shortName: string;
        longName: string;
        lab: { __typename?: 'Lab'; id: string; shortName: string };
      };
    }>;
    diagnoses: Array<{
      __typename?: 'Diagnose';
      id: string;
      text: string;
      lab: { __typename?: 'Lab'; id: string; shortName: string };
    }>;
  }> | null;
  catalogParameters?: Array<{
    __typename?: 'CatalogParameter';
    id: string;
    parameter: {
      __typename?: 'Parameter';
      id: string;
      shortName: string;
      longName: string;
      synonyms: Array<string>;
      lab: { __typename?: 'Lab'; id: string; shortName: string };
    };
  }> | null;
  diagnoses?: Array<{
    __typename?: 'Diagnose';
    id: string;
    text: string;
    lab: { __typename?: 'Lab'; id: string; shortName: string };
  }> | null;
  doctorsForCurrentUser?: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    meAddress: string;
    firstName: string;
    lastName: string;
    postTitle: string;
    preTitle: string;
    salutation: DoctorSalutation;
    disabled: boolean;
  }> | null;
  doctor?: { __typename?: 'Doctor'; id: string; flipParamNames: boolean } | null;
};

export type DeleteProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProfileMutation = { __typename?: 'Mutation'; deleteProfile?: boolean | null };

export type DoctorSheetQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type DoctorSheetQuery = {
  __typename?: 'Query';
  doctorSheets?: Array<{ __typename?: 'Sheet'; id: string; data: any }> | null;
  catalogParameters?: Array<{
    __typename?: 'CatalogParameter';
    id: string;
    parameter: { __typename?: 'Parameter' } & {
      ' $fragmentRefs'?: { SheetEditor_ParameterFragment: SheetEditor_ParameterFragment };
    };
  }> | null;
  doctor?: { __typename?: 'Doctor'; id: string; flipParamNames: boolean } | null;
};

export type SaveDoctorSheetMutationVariables = Exact<{
  doctorId: Scalars['ID']['input'];
  input: SheetInput;
}>;

export type SaveDoctorSheetMutation = {
  __typename?: 'Mutation';
  saveDoctorSheet?: { __typename?: 'Sheet'; id: string } | null;
};

export type LabAdminLabQueryVariables = Exact<{ [key: string]: never }>;

export type LabAdminLabQuery = {
  __typename?: 'Query';
  assignmentForCurrentUser?: {
    __typename?: 'UserAssignment';
    id: string;
    primaryDoctor: {
      __typename?: 'Doctor';
      id: string;
      assignedLabs: Array<{
        __typename?: 'AssignedLab';
        lab: { __typename?: 'Lab'; id: string; name: string; longName: string; shortName: string };
      }>;
    };
  } | null;
};

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile?: { __typename?: 'Profile'; id: string } | null;
};

export type DoctorsForCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type DoctorsForCurrentUserQuery = {
  __typename?: 'Query';
  doctorsForCurrentUser?: Array<{
    __typename?: 'Doctor';
    id: string;
    name: string;
    meAddress: string;
    firstName: string;
    lastName: string;
    postTitle: string;
    preTitle: string;
    salutation: DoctorSalutation;
    disabled: boolean;
  }> | null;
};

export type UserMenuQueryVariables = Exact<{
  doctorId: Scalars['ID']['input'];
}>;

export type UserMenuQuery = {
  __typename?: 'Query';
  doctor?: { __typename?: 'Doctor'; id: string; name: string; disabled: boolean } | null;
  assignmentForCurrentUser?: {
    __typename?: 'UserAssignment';
    id: string;
    primaryDoctor: { __typename?: 'Doctor'; id: string };
    additionalDoctors: Array<{ __typename?: 'Doctor'; id: string }>;
  } | null;
};

export const SheetEditor_ParameterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetEditor_Parameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Parameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SheetEditor_ParameterFragment, unknown>;
export const RequestCopyDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestCopyData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestCopy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'labInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insuredPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedParameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestableParameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainParameterId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withParameterNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingInfos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specimens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'testTubes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unknownBillingParameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'unavailableParameters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formFieldId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paramId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestCopyDataFragment, unknown>;
export const DoctorForm_LabFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Lab' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Lab' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorForm_LabFragment, unknown>;
export const DoctorForm_ThemeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Theme' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorForm_ThemeFragment, unknown>;
export const DoctorForm_CatalogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Catalog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Catalog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorForm_CatalogFragment, unknown>;
export const DoctorForm_InsuranceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Insurance' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Insurance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorForm_InsuranceFragment, unknown>;
export const DoctorForm_SpecialRateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_SpecialRate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecialRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorForm_SpecialRateFragment, unknown>;
export const UpdateDoctorModal_DoctorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateDoctorModal_Doctor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Doctor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialist' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedLabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runningNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisImportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisImportDoctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPreference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferSpecialRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoDiagnose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSpecialRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lgInsurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'printTransferDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showPrices' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showPriceSuggestion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisExportTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addEmptyLabels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'csvImportEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideRequestEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDoctorModal_DoctorFragment, unknown>;
export const CatalogForm_ParameterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CatalogForm_Parameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Parameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogForm_ParameterFragment, unknown>;
export const FormRenderer_FormFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FormRenderer_Form' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Form' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'help' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormRenderer_FormFragment, unknown>;
export const OpenRequestsBySvnrModal_RequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OpenRequestsBySvnrModal_Request' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenRequestsBySvnrModal_RequestFragment, unknown>;
export const ParameterSelection_RequestableParameterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_RequestableParameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestableParameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainParameterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withParameterNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingInfos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specimens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testTubes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterSelection_RequestableParameterFragment, unknown>;
export const ParameterSelection_ProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_Profile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hotKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredBillingType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'diagnoses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterSelection_ProfileFragment, unknown>;
export const ParameterSelection_DoctorSheetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_DoctorSheet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Sheet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterSelection_DoctorSheetFragment, unknown>;
export const RequestSummary_SavedRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSummary_SavedRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisExportedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderFiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestSummary_SavedRequestFragment, unknown>;
export const AssignmentForCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AssignmentForCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryDoctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignmentForCurrentUserQuery, AssignmentForCurrentUserQueryVariables>;
export const UserDoctorAuthCheckQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserDoctorAuthCheckQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isCurrentUserAuthorizedForDoctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDoctorAuthCheckQueryQuery, UserDoctorAuthCheckQueryQueryVariables>;
export const SystemStatusWrapperDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SystemStatusWrapper' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SystemStatusWrapperQuery, SystemStatusWrapperQueryVariables>;
export const MarkAisExportedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAisExported' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAisExported' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkAisExportedMutation, MarkAisExportedMutationVariables>;
export const CheckParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedParameters' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectedParameterInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientDataInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedParameters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedParameters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestableParameter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainParameterId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterIds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingInfos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'specimens' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'testTubes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'withParameterNames' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterNames' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unknownBillingParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'unavailableParameters' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckParametersQuery, CheckParametersQueryVariables>;
export const DoctorAisExportTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorAisExportTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aisExportTypes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorAisExportTypesQuery, DoctorAisExportTypesQueryVariables>;
export const DoctorHasAisDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorHasAis' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAis' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorHasAisQuery, DoctorHasAisQueryVariables>;
export const DoctorThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'theme' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colorBgHeader' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colorBgTrigger' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'colorPrimary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'controlItemBgActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'controlItemBgActiveHover' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoData' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorThemeQuery, DoctorThemeQueryVariables>;
export const LabSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LabSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetEditor_Parameter' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetEditor_Parameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Parameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabSheetQuery, LabSheetQueryVariables>;
export const SaveLabSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveLabSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveLabSheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveLabSheetMutation, SaveLabSheetMutationVariables>;
export const MarkPrintedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkPrinted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'beforeSend' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markPrinted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'beforeSend' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'beforeSend' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkPrintedMutation, MarkPrintedMutationVariables>;
export const RequestCopyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestCopy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPatientData' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientDataInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestCopy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPatientData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPatientData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RequestCopyData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestCopyData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestCopy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'labInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insuredPerson' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedParameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestableParameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainParameterId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'withParameterNames' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingInfos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specimens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'testTubes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unknownBillingParameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'unavailableParameters' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formFieldId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paramId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestCopyQuery, RequestCopyQueryVariables>;
export const SendRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aisExportedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderLabels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendRequestsMutation, SendRequestsMutationVariables>;
export const FrontDeskDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FrontDeskDoctor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryDoctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedLabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importedDoctors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specialist' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hvNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FrontDeskDoctorQuery, FrontDeskDoctorQueryVariables>;
export const CreateFrontDeskDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFrontDeskDoctor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDoctorFrontDeskInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDoctorFrontDesk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFrontDeskDoctorMutation, CreateFrontDeskDoctorMutationVariables>;
export const DashboardDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'openRequests' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentRequestsToday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentRequestsWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentRequestsMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentRequestsYear' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardDataQuery, DashboardDataQueryVariables>;
export const DoctorSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorSearch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorsForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedLabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorSearchQuery, DoctorSearchQueryVariables>;
export const FibuExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FibuExport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryDoctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedLabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FibuExportQuery, FibuExportQueryVariables>;
export const OpenFrontDeskRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OpenFrontDeskRequests' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openFrontDeskRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'doctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedLabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'customerId' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenFrontDeskRequestsQuery, OpenFrontDeskRequestsQueryVariables>;
export const OpenRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OpenRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentDoctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentDoctorId' } },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'svnr' }, value: { kind: 'NullValue' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sampledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderLabels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insuredPerson' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByLastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addEmptyLabels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'csvImportEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenRequestsQuery, OpenRequestsQueryVariables>;
export const DeleteAllOpenRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAllOpenRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllOpenRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAllOpenRequestsMutation, DeleteAllOpenRequestsMutationVariables>;
export const DeleteOpenRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOpenRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOpenRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOpenRequestMutation, DeleteOpenRequestMutationVariables>;
export const PatientSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientSearchFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'exact' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceShortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'insuredPerson' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientSearchQuery, PatientSearchQueryVariables>;
export const SentRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageable' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Pageable' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestFilter' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageable' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageable' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampledAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'insuredPerson' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reorderable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reorderableUntil' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modifiedByLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sentByFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sentByLastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentRequestsQuery, SentRequestsQueryVariables>;
export const DoctorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Doctors' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UpdateDoctorModal_Doctor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorForm_Lab' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorForm_Catalog' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorForm_Insurance' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorForm_SpecialRate' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorForm_Theme' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateDoctorModal_Doctor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Doctor' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialist' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hvNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedLabs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runningNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisImportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisImportDoctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingPreference' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferSpecialRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoDiagnose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSpecialRate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lgInsurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'printTransferDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showPrices' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showPriceSuggestion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aisExportTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addEmptyLabels' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'csvImportEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideRequestEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Lab' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Lab' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Catalog' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Catalog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Insurance' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Insurance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_SpecialRate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecialRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorForm_Theme' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorsQuery, DoctorsQueryVariables>;
export const InsurancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Insurances' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mappings' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsurancesQuery, InsurancesQueryVariables>;
export const LabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Lab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabQuery, LabQueryVariables>;
export const CompileParamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompileParams' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'compileParams' } }],
      },
    },
  ],
} as unknown as DocumentNode<CompileParamsMutation, CompileParamsMutationVariables>;
export const LabsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Labs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabsQuery, LabsQueryVariables>;
export const UpdateSystemStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSystemStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemStatusInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSystemStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>;
export const SystemStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SystemStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SystemStatusQuery, SystemStatusQueryVariables>;
export const ThemesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Themes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colorPrimary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controlItemBgActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controlItemBgActiveHover' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colorBgHeader' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colorBgTrigger' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThemesQuery, ThemesQueryVariables>;
export const DoctorsForAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorsForAssignment' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorsForAssignmentQuery, DoctorsForAssignmentQueryVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryDoctor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additionalDoctors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const DeleteUserAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserAssignmentMutation, DeleteUserAssignmentMutationVariables>;
export const CreateDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDoctor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDoctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDoctorMutation, CreateDoctorMutationVariables>;
export const ImportLookupDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImportLookupDoctor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportLookupDoctorQuery, ImportLookupDoctorQueryVariables>;
export const UpdateDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDoctor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDoctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDoctorMutation, UpdateDoctorMutationVariables>;
export const CreateInsuranceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInsurance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InsuranceInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInsurance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInsuranceMutation, CreateInsuranceMutationVariables>;
export const UpdateInsuranceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInsurance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InsuranceInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInsurance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInsuranceMutation, UpdateInsuranceMutationVariables>;
export const BasedataLabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BasedataLab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zplLabelTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zplLabelWidth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zplLabelHeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newReorderRunningNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pickupTransferDocument' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printPartnerLab1Params' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printPartnerLab2Params' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printPartnerLab3Params' } },
                { kind: 'Field', name: { kind: 'Name', value: 'runningNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceCounter' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasedataLabQuery, BasedataLabQueryVariables>;
export const UpdateLabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LabInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLabMutation, UpdateLabMutationVariables>;
export const CatalogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Catalogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labCatalogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CatalogForm_Parameter' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CatalogForm_Parameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Parameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogsQuery, CatalogsQueryVariables>;
export const CloneCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CloneCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cloneCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CloneCatalogMutation, CloneCatalogMutationVariables>;
export const DeleteCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCatalogMutation, DeleteCatalogMutationVariables>;
export const DiagnosesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Diagnoses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labDiagnoses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiagnosesQuery, DiagnosesQueryVariables>;
export const DeleteLabTransferDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLabTransferDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLabTransferDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLabTransferDocumentMutation, DeleteLabTransferDocumentMutationVariables>;
export const DeleteLabAisExportDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLabAisExportDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLabAisExportDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLabAisExportDocumentMutation, DeleteLabAisExportDocumentMutationVariables>;
export const DocumentsLabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocumentsLab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTransferDocument' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAisExportDocument' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsLabQuery, DocumentsLabQueryVariables>;
export const FormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Forms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pickup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasDocument' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customizer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'help' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormsQuery, FormsQueryVariables>;
export const ParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Parameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specimens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withoutParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specialRates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edifactNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerLab' } },
                { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParametersQuery, ParametersQueryVariables>;
export const PeriodicGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PeriodicGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labPeriodicGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PeriodicGroupsQuery, PeriodicGroupsQueryVariables>;
export const SpecialRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SpecialRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecialRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpecialRatesQuery, SpecialRatesQueryVariables>;
export const SpecimensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Specimens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecimens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testTubes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labTestTubes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpecimensQuery, SpecimensQueryVariables>;
export const TestTubesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TestTubes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labTestTubes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestTubesQuery, TestTubesQueryVariables>;
export const CatalogParameterFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CatalogParameterForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecialRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labDiagnoses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecimens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labPeriodicGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogParameterFormQuery, CatalogParameterFormQueryVariables>;
export const CatalogParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CatalogParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'catalogParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSpecimens' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specimens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSpecialRates' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specialRates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useForms' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'useDoctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usePatientBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useLgDoctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useInsuranceDefinitions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useLgInsuranceDefinitions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usePriceDoctor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usePricePatient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usePriceLgDoctor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'edifactNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'withoutParameters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'withParameters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'specimens' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'specialRates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'forms' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogParametersQuery, CatalogParametersQueryVariables>;
export const CopyCatalogParametersMutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyCatalogParametersMut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CopyCatalogParametersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyCatalogParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyCatalogParametersMutMutation, CopyCatalogParametersMutMutationVariables>;
export const CopyCatalogParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CopyCatalogParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'catalogParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labCatalogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyCatalogParametersQuery, CopyCatalogParametersQueryVariables>;
export const CreateCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CatalogCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCatalogMutation, CreateCatalogMutationVariables>;
export const CatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Catalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'catalogParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CatalogQuery, CatalogQueryVariables>;
export const UpdateCatalogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCatalog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CatalogUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCatalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCatalogMutation, UpdateCatalogMutationVariables>;
export const UpdateCatalogParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCatalogParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CatalogParameterUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCatalogParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCatalogParametersMutation, UpdateCatalogParametersMutationVariables>;
export const MultiCatalogParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MultiCatalogParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'individualCatalogParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insuranceDefinitions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'diagnoses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodicGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specialRates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specimens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'useForms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useLgDoctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useLgInsuranceDefinitions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useSpecimens' } },
                { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useDoctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useInsuranceDefinitions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usePatientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useSpecialRates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usePriceDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usePriceLgDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usePricePatient' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specimens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'withParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'withoutParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insuranceDefinitions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'insurance' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diagnoses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'periodicGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specialRates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'specimens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'edifactNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MultiCatalogParametersQuery, MultiCatalogParametersQueryVariables>;
export const CreateDiagnoseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDiagnose' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiagnoseCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDiagnose' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDiagnoseMutation, CreateDiagnoseMutationVariables>;
export const UpdateDiagnoseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDiagnose' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiagnoseUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDiagnose' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDiagnoseMutation, UpdateDiagnoseMutationVariables>;
export const CreateFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFormMutation, CreateFormMutationVariables>;
export const DeleteFormDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFormDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFormDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFormDocumentMutation, DeleteFormDocumentMutationVariables>;
export const UpdateFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFormMutation, UpdateFormMutationVariables>;
export const CreateParameterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateParameter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParameterCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createParameter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateParameterMutation, CreateParameterMutationVariables>;
export const ParameterFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ParameterForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecialRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labDiagnoses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labSpecimens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labPeriodicGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'labId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterFormQuery, ParameterFormQueryVariables>;
export const UpdateParameterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParameterUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateParameter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateParameterMutation, UpdateParameterMutationVariables>;
export const ParameterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Parameter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labParameter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specimens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withoutParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insuranceDefinitions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'diagnoses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodicGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specialRates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'doctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edifactNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lgDoctorBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceLgDoctor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerLab' } },
                { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterQuery, ParameterQueryVariables>;
export const CreatePeriodicGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePeriodicGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PeriodicGroupCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPeriodicGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePeriodicGroupMutation, CreatePeriodicGroupMutationVariables>;
export const UpdatePeriodicGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePeriodicGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PeriodicGroupUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePeriodicGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePeriodicGroupMutation, UpdatePeriodicGroupMutationVariables>;
export const CreateSpecialRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSpecialRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecialRateCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpecialRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSpecialRateMutation, CreateSpecialRateMutationVariables>;
export const UpdateSpecialRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSpecialRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecialRateUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSpecialRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSpecialRateMutation, UpdateSpecialRateMutationVariables>;
export const CreateSpecimenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSpecimen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecimenCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSpecimen' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSpecimenMutation, CreateSpecimenMutationVariables>;
export const UpdateSpecimenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSpecimen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SpecimenUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSpecimen' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSpecimenMutation, UpdateSpecimenMutationVariables>;
export const CreateTestTubeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTestTube' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TestTubeCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTestTube' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTestTubeMutation, CreateTestTubeMutationVariables>;
export const DeleteTestTubeImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTestTubeImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTestTubeImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTestTubeImageMutation, DeleteTestTubeImageMutationVariables>;
export const UpdateTestTubeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTestTube' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TestTubeUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTestTube' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTestTubeMutation, UpdateTestTubeMutationVariables>;
export const CreateThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTheme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateThemeMutation, CreateThemeMutationVariables>;
export const UpdateThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTheme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateThemeMutation, UpdateThemeMutationVariables>;
export const SaveUserAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveUserAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserAssignmentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveUserAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveUserAssignmentMutation, SaveUserAssignmentMutationVariables>;
export const DailyReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DailyReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedLabs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DailyReportQuery, DailyReportQueryVariables>;
export const DirectFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DirectForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FormRenderer_Form' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FormRenderer_Form' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Form' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'header' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'help' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectFormsQuery, DirectFormsQueryVariables>;
export const PatientInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientInfoQuery, PatientInfoQueryVariables>;
export const ParameterSelectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ParameterSelection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientDataInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestableParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestIdForReorder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ParameterSelection_RequestableParameter' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ParameterSelection_Profile' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorSheets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'withLabSheets' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ParameterSelection_DoctorSheet' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showPriceSuggestion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showPrices' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overrideRequestEnabled' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_RequestableParameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestableParameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainParameterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withoutParameterNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withParameterNames' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingInfos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePatient' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specimens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storagePeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresLocalizations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localizationInputEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predefinedLocalizations' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testTubes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelText2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnoseRequired' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'evalHours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_Profile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hotKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredBillingType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'diagnoses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParameterSelection_DoctorSheet' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Sheet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParameterSelectionQuery, ParameterSelectionQueryVariables>;
export const OpenRequestsBySvnrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OpenRequestsBySvnr' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'svnr' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpenRequestsBySvnrModal_Request' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OpenRequestsBySvnrModal_Request' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenRequestsBySvnrQuery, OpenRequestsBySvnrQueryVariables>;
export const QueryParameterSelectionPatientRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QueryParameterSelectionPatientRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'svnr' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reorderable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QueryParameterSelectionPatientRequestsQuery,
  QueryParameterSelectionPatientRequestsQueryVariables
>;
export const AddParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddParametersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'incompatibleParams' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paramsToAdd' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'parameterId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddParametersMutation, AddParametersMutationVariables>;
export const PatientRegistrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientRegistrationQuery, PatientRegistrationQueryVariables>;
export const RequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Request' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sampledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceShortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insuranceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'importType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'importRequestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceCancellationNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderLabels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'classification' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paramShortNames' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specialRateShortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'acute' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pickup' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderForms' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fields' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insuredPerson' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedByFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentByFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentByLastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showPrices' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestQuery, RequestQueryVariables>;
export const MarkOrderLabelPrintedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkOrderLabelPrinted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderLabelId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderLabelPrinted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderLabelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderLabelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkOrderLabelPrintedMutation, MarkOrderLabelPrintedMutationVariables>;
export const MarkOrderFilePrintedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkOrderFilePrinted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderFileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderFilePrinted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderFileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderFileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkOrderFilePrintedMutation, MarkOrderFilePrintedMutationVariables>;
export const CancelOrderInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelOrderInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelOrderInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelOrderInvoiceMutation, CancelOrderInvoiceMutationVariables>;
export const RequestSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogParamIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insurances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requiredForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogParamIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogParamIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestIdForReorder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'help' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showPriceSuggestion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showPrices' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addEmptyLabels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestSummaryQuery, RequestSummaryQueryVariables>;
export const CreateRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RequestSummary_SavedRequest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSummary_SavedRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisExportedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderFiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRequestMutation, CreateRequestMutationVariables>;
export const UpdateRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RequestSummary_SavedRequest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSummary_SavedRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisExportedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderFiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRequestMutation, UpdateRequestMutationVariables>;
export const ReorderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Reorder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReorderInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reorder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RequestSummary_SavedRequest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestSummary_SavedRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisExportedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderFiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printBeforeSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printCopies' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'testTubeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalData' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReorderMutation, ReorderMutationVariables>;
export const LocalizationsChooserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalizationsChooser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderLabels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'specimenId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalizationsChooserQuery, LocalizationsChooserQueryVariables>;
export const CreateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProfileMutation, CreateProfileMutationVariables>;
export const PatientRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'svnr' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'svnr' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reorderable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reorderableUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByFrontDesk' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardAuthority' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idCardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceCategory' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insuredPerson' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'svnr' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientRequestsQuery, PatientRequestsQueryVariables>;
export const AvailableBillingInfosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailableBillingInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'catalogParamId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedParameters' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectedParameterInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientDataInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableBillingInfos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'catalogParamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'catalogParamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientData' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestIdForReorder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestIdForReorder' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedParameters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedParameters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingInfos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'diagnoseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialRateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exceededPeriodicGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodicGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxCount' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paramsInGroup' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableBillingInfosQuery, AvailableBillingInfosQueryVariables>;
export const RequestForReorderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestForReorder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestForReorderQuery, RequestForReorderQueryVariables>;
export const DoctorSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specialist' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPreference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoDiagnose' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addEmptyLabels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'printTransferDocument' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorSettingsQuery, DoctorSettingsQueryVariables>;
export const UpdateDoctorSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDoctorSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDoctorSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDoctorSettingsMutation, UpdateDoctorSettingsMutationVariables>;
export const ProfilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Profiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hotKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredBillingType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'diagnoses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diagnose' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lab' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'diagnoses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lab' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorsForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfilesQuery, ProfilesQueryVariables>;
export const DeleteProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileMutation, DeleteProfileMutationVariables>;
export const DoctorSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorSheets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'withLabSheets' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalogParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SheetEditor_Parameter' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flipParamNames' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SheetEditor_Parameter' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Parameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synonyms' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lab' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorSheetQuery, DoctorSheetQueryVariables>;
export const SaveDoctorSheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveDoctorSheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SheetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveDoctorSheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'doctorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveDoctorSheetMutation, SaveDoctorSheetMutationVariables>;
export const LabAdminLabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LabAdminLab' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryDoctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedLabs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lab' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'longName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabAdminLabQuery, LabAdminLabQueryVariables>;
export const UpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const DoctorsForCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorsForCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorsForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorsForCurrentUserQuery, DoctorsForCurrentUserQueryVariables>;
export const UserMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserMenu' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'doctorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentForCurrentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryDoctor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalDoctors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserMenuQuery, UserMenuQueryVariables>;
